export const StringFormatter = {
    repeat: (value: string|number, symbol: string, count: number) => {
        const diff = count - String(value).length;
        return diff > 0 ? (String(symbol)).repeat(diff) : "";
    },
    leftPad: (value: string|number, symbol: string, count: number) => {
        return StringFormatter.repeat(value, symbol, count) + value;
    },
    rightPad: (value: string|number, symbol: string, count: number) => {
        return value + StringFormatter.repeat(value, symbol, count);
    },
    trim: (value: string, length: number) => {
        if (value === undefined || value === null) {
            return value;
        }
        value = String(value);
        if (value.length > length) {
            value = value.substr(0, length);
        }

        return value;
    },
    ucFirst: (value: string) => {
        if (typeof value === 'undefined' || value === null || String(value).length === 0) {
            return value;
        }
        value = String(value).replace(/^\s+/, '');
        value = value.substr(0, 1).toUpperCase() + (value.length > 1 ? value.substr(1) : '');

        return value;
    },
    // nl2br: (value: string) => {
    //     if (!value) {
    //         return value;
    //     }

    //     value = value
    //         .split(/(?:\r\n|\r|\n)/)
    //         .map((textItem, idx) => (<React.Fragment key={idx}>{textItem}<br/></React.Fragment>) )
    //         //.join(React.createElement("br"))
    //         // .reduce((res, frag, i, arr) => [
    //         //     ...res,
    //         //     frag,
    //         //     ...(i < arr.length - 1 && '<br/>')
    //         // ], []);

    //     return value;
    // }
}

export const NumberFormatter = {
    format: (value: string, decimals = 0, thousand = ',') => {
        const fract = NumberFormatter.formatFract(value, decimals);
        value = NumberFormatter.formatInt(value, thousand);

        return value + (fract ? '.' + fract : '');
    },
    parseFract: (value: string, count: number) => {
        return Math.round(Math.pow(10, count) * (parseFloat(value) - parseInt(value)));
    },
    formatFract: (value: string, count: number) => {
        const fract = NumberFormatter.parseFract(value, count);

        return fract === 0 ? '' : StringFormatter.rightPad(fract, "0", count);
    },
    formatInt: (value: string, separator: string) => {
        let valueInt = parseInt(value);
        const res = [];
        while (valueInt > 0) {
            const fract = NumberFormatter.parseFract(String(valueInt / 1000), 3);
            const pad = 3; //fract > 0 ? 3-Math.ceil(Math.log10(fract)) : 3;
            valueInt = Math.floor(valueInt / 1000);
            const part = valueInt >= 1 && pad > 0 ? StringFormatter.leftPad(fract, "0", pad) : fract;
            res.push(part);
        }

        return res.length ? res.reverse().join(separator) : String(valueInt);
    }
}

export const MoneyFormatter = {
    symbol: '$',
    currency: 'USD',
    format: (value: string, decimals?: number) => {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        let maxDigits = 2;
        if (decimals === undefined || NumberFormatter.parseFract(value, decimals) === 0) {
            decimals = 0;
            maxDigits = 0;
        }

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: MoneyFormatter.currency,
            minimumFractionDigits: decimals,
            maximumFractionDigits: maxDigits
        }).format(+value);
    }
}

export const DateFormatter = {
    timezone: '',
    stringDbDate2LongStringDate: (value: string) => {
        let commonOptions = {};
        if (DateFormatter.timezone) {
            commonOptions = {timeZone: DateFormatter.timezone};
        }
        const date = new Date(value);
        return date.toLocaleString('en-US', { month: 'long', ...commonOptions }) + ' ' +
            date.toLocaleString('en-US', { day: 'numeric', ...commonOptions }) + ', ' +
            date.toLocaleString('en-US', { year: 'numeric', ...commonOptions });
    },
    stringDbDate2StringTime: (value: string) => {
        let commonOptions = {};
        if (DateFormatter.timezone) {
            commonOptions = {timeZone: DateFormatter.timezone};
        }
        const date = new Date(value);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, ...commonOptions })
    },
    stringDbDate2StringDate: (value: string) => {
        return DateFormatter.dateObject2StringDate(new Date(value))
    },
    timestamp2StringDate: (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        return DateFormatter.dateObject2StringDate(date);
    },
    dateObject2StringDate: (date: Date, isLeadingZero = false) => {
        const values = [
            isLeadingZero ? StringFormatter.leftPad(1 + date.getMonth(), '0', 2) : (1 + date.getMonth()),
            isLeadingZero ? StringFormatter.leftPad(date.getDate(), '0', 2) : date.getDate(),
            (+date.getFullYear() - 2000)
        ];
        return values.join('/');
    },
    stringDbDate2StringDay: (value: string) => {
        const date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    },
    isToday: (date: Date) => {
        if (!date) {
            return null;
        }
        return DateFormatter.dateObject2StringDate(date) === DateFormatter.dateObject2StringDate(new Date());
    },
    isEqDay: (date1: string, date2: string) => {
        if (!date1 || !date2) {
            return false;
        }

        return DateFormatter.stringDbDate2StringDay(date1) === DateFormatter.stringDbDate2StringDay(date2);
    },
    isExpired: (date: Date|string) => {
        date instanceof Date || (date = new Date(date));
        const now = new Date();
        return now > date;
    },
    toDayMonth: (date: Date|string, opts: any) => {
        opts || (opts = {});
        date = date instanceof Date ? date : new Date(date);
        if (!date) {
            return null;
        }
        const formatter = new Intl.DateTimeFormat("en-US", {
            month: 'long', day: 'numeric',
            ...opts
        });

        return DateFormatter.isToday(date) ? 'Today' : formatter.format(date);
    }
};

export const TimeFormatter = {
    timestamp2StringTime: (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        return TimeFormatter.format(date);
    },
    format: (date: Date, opts?: any) => {
        opts || (opts = {});
        const formatter = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            ...opts
        });
        date = date instanceof Date ? date : new Date(date);

        return formatter.format(date);
    },
    formatSmallWithoutSpaces: (date: Date) => {
        return String(TimeFormatter.format(date)).toLowerCase().replace(' ', '');
    }
}

export const ZipFormatter = {
    zipLength: 5,
    format: (value: string|number) => {
        value = ['string', 'number'].indexOf(typeof value) > -1 ? String(value) : "";
        value = StringFormatter.trim(value, ZipFormatter.zipLength);
        value = StringFormatter.repeat('', '0', ZipFormatter.zipLength - value.length) + value;
        return value;
    }
}

export const PhoneFormatter = {
    format: (value: string) => {
        value = String(value).replace(/[^\d]+/, '');
        const match  = value.match(/(\d{3})(\d{3})(\d{4})$/);
        const res = match ? ['(' + match[1] + ')', ' ', match[2], '-', match[3]].join('') : null;

        return res;
    }
}