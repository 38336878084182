import React, {useEffect, useState} from 'react';
// import logo from './logo.svg';
import './App.css';
import { ScopedCssBaseline, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { getTheme } from './theme';
import { NavigateFunction, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './containers/Auth/Login';
import AuthLayout from './components/layouts/auth-layout';
import MainLayout from './components/layouts/main-layout';
import FogotPassword from './containers/Auth/FogotPassword';
import SetPassword from './containers/Auth/SetPassword';
import ResetPassword from './containers/Auth/ResetPassword';
import { connect, useStore } from 'react-redux';
import { RootState } from './app/store';
import { authCheckState, authInit, authSetTokenTimer } from './store/actions';
import { useAppSelector } from './app/hooks';
import Dashboard from './containers/Dashboard/Dashboard';
import OfferDetail from './containers/Dashboard/OfferDetail';
import SnackBar from './components/snackbars/SnackBar';
import WhyCarwiser from "./containers/WhyCarwiser/WhyCarwiser";
import MyAccount from './containers/Account/MyAccount';
import ChangePassword from './containers/Account/ChangePassword';
import vwoHelper from "./shared/VwoHelper";
import heapHelper from "./shared/HeapHelper"

class AppPropsDto {
  darkMode?: boolean;
  onInitApp: any;
  onTryAutoSignup: any;
  onSetTokenTimer: any;
}

function App(props: AppPropsDto) {
  const [vwoLoaded, setVWOLoaded] = useState(false);
  const [heapLoaded, setHeapLoaded] = useState(false);

  useEffect(() => {
    vwoHelper.load(() => {
      setVWOLoaded(true);
    })
  }, []);

  useEffect(() => {
    heapHelper.load(() => {
      setHeapLoaded(true);
    })
  }, [])

  const theme = React.useMemo(() => getTheme(props.darkMode), [props.darkMode]);
  const store = useStore();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated);
  const authToken = useAppSelector((state: RootState) => state.auth.token);

  React.useEffect(() => {
    const state = store.getState() as RootState;
    if (!state.auth.isInited) {
      props.onInitApp();
    }
    props.onTryAutoSignup(state, navigate);
  }, [props, navigate, store]); // state.auth.token
  const isAuthPath = window.location.pathname.indexOf('/auth') === 0;

  React.useEffect(() => {
    if ((!isAuthenticated || !authToken) && !isAuthPath) {
        navigate('/login');
        return ;
    }
    props.onSetTokenTimer(authToken);
  }, [props, isAuthenticated, isAuthPath, authToken, navigate])
  const classes = ['main-wrapper'];
  isMobile && classes.push('mobile');
  if (isAuthenticated && !isAuthPath) {
    document.body.classList.remove('auth');
  } else {
    document.body.classList.add('auth');
    classes.push('auth');
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline enableColorScheme={true} className={classes.join(' ')}>
        <Routes>
          <Route path="/">
            <Route path="/dashboard" element={<MainLayout><Dashboard/></MainLayout>}/>
            <Route path="/offer" element={<MainLayout><OfferDetail/></MainLayout>}/>
            <Route path="/why-carwiser" element={<MainLayout><WhyCarwiser/></MainLayout>}/>
            <Route path="/account" element={<MainLayout><MyAccount/></MainLayout>}/>
            <Route path="/account/settings" element={<MainLayout><ChangePassword/></MainLayout>}/>
          </Route>
          <Route path="/login" element={<AuthLayout><Login/></AuthLayout>}/>
          <Route path="/auth">
            <Route path="set-password" element={<AuthLayout><SetPassword/></AuthLayout>}/>
            <Route path="reset-password" element={<AuthLayout><ResetPassword/></AuthLayout>}/>
            <Route path="forgot-password" element={<AuthLayout><FogotPassword/></AuthLayout>}/>
          </Route>
          {/* <Route path="/register" />  */}
        </Routes>
        <SnackBar/>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
      onTryAutoSignup: (state: RootState, navigate: NavigateFunction) => dispatch(
        authCheckState(state, navigate)),
      onInitApp: () => dispatch(authInit()),
      onSetTokenTimer: (token: any) => dispatch(authSetTokenTimer(token))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
