import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './SelectTimeField.css';
import checkImg from "../../img/check-circle.svg";
import { Box } from "@mui/material";

class SelectTimeFieldProps {
  label: string = '';
  value: string = '';
  checked?: boolean = false;
  onChange: any;
  minTime?: string = '';
  maxTime?: string = '';
  disabled?: boolean = false;
}

export function SelectTimeField(props: SelectTimeFieldProps) {

  const {value, label, onChange, checked, minTime, maxTime, disabled} = props;

  const hours = ['12:00 AM', '12:30 AM',
                  '1:00 AM', '1:30 AM', 
                  '2:00 AM', '2:30 AM',
                  '3:00 AM', '3:30 AM',
                  '4:00 AM', '4:30 AM',
                  '5:00 AM', '5:30 AM',
                  '6:00 AM', '6:30 AM',
                  '7:00 AM', '7:30 AM',
                  '8:00 AM', '8:30 AM',
                  '9:00 AM', '9:30 AM',
                  '10:00 AM', '10:30 AM',
                  '11:00 AM', '11:30 AM',
                  '12:00 PM', '12:30 PM',
                  '1:00 PM', '1:30 PM', 
                  '2:00 PM', '2:30 PM',
                  '3:00 PM', '3:30 PM',
                  '4:00 PM', '4:30 PM',
                  '5:00 PM', '5:30 PM',
                  '6:00 PM', '6:30 PM',
                  '7:00 PM', '7:30 PM',
                  '8:00 PM', '8:30 PM',
                  '9:00 PM', '9:30 PM',
                  '10:00 PM', '10:30 PM',
                  '11:00 PM', '11:30 PM']

  const hoursToShow = hours.filter(hour => {
    if (minTime && hours.indexOf(minTime) && hours.indexOf(minTime) > hours.indexOf(hour)) return false;
    if (maxTime && hours.indexOf(maxTime) && hours.indexOf(maxTime) < hours.indexOf(hour) + 1) return false; // ' + 1' to exclude closing hours 
    return true;
  })

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <Box className="time-select">
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value as string}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          MenuProps={{
            style: {
               maxHeight: 300,
            }
          }}
        >
          {hoursToShow.map(hour => {
            return <MenuItem key={hour.replace(' ', '')} value={hour}>{hour}</MenuItem>
          })}
        </Select>
        {checked && <img className="errorImg" src={checkImg} alt="check img" />}
      </FormControl>   
    </Box>
)}