import {Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress} from '@mui/material';
import React from 'react';
import './Button.css';

class CustomButtonProps {
    disabled?: boolean;
    paddingLeft?: any;
    loading?: boolean;
    target?: string;
}

export type BtnProps = MuiButtonProps & CustomButtonProps;

export function Button(props: BtnProps) {
    let {disabled, paddingLeft, loading, variant, ...restProps} = props;
    if (loading) {
        disabled = !!loading;
    }
    variant || (variant = 'contained');

    const buttonStyle = {
        paddingLeft: paddingLeft,
        paddingRight: paddingLeft,
    }

    return (
        <React.Fragment>
            <MuiButton
                className={"form-button"}
                variant={variant}
                disabled={disabled}
                style={buttonStyle}
                {...restProps}
            >
                {props.children}
                {loading && <CircularProgress size={24} className={'button-progress'}/>}
            </MuiButton>
        </React.Fragment>
    )
}
