import {Box, Breadcrumbs as MuiBreadcrumbs} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import RouterLink from '../router/RouterLink';
import React from "react";

type BreadcrumbsDto = {
	showAsterisk: boolean | null
}

const sxStyles = {
	p: {xs: '16px 0px', sm: '40px 0px 20px 0px'},
	'& .MuiBreadcrumbs-li a, & .MuiBreadcrumbs-li p': {
		fontWeight: {xs: 600},
		fontSize: {xs: '20px', sm: '36px'},
		lineHeight: {xs: '28px', sm: '40px'},
		color: '#2A2F31',
	}
}

function Breadcrumbs(props: BreadcrumbsDto) {
	// todo:
	const breadcrumbsItems = [
		<RouterLink underline="hover" key="1" color="inherit" href="/dashboard">
			Other Offers
		</RouterLink>,
		<Typography key="3" color="text.primary" sx={{
			position: 'relative',
		}}>
			Your Offer
			{props.showAsterisk && (
				<Box component={'span'} sx={{
					position: 'absolute',
					right: '-12px',
					top: {xs: '-1px', md: '2px'},
					display: 'inline',
					fontSize: '24px',
					lineHeight: '32px',
					fontWeight: 400,
					color: '#2BACE2',
					textAlign: 'center',
				}}>*</Box>
			)}
		</Typography>,
	];

	return (
		<MuiBreadcrumbs separator={<ArrowForwardIosRoundedIcon sx={{
			fontSize: {xs: '18px', sm: '32px'},
		}}/>} aria-label="breadcrumb" sx={sxStyles}>
			{breadcrumbsItems}
		</MuiBreadcrumbs>
	)
}

export default Breadcrumbs;