export default class MatchingClients {

	static PrimaryNames: any = {
		Default: 'default',
		Carvana: 'carvana',
		Vroom: 'vroom',
		Carmax: 'carmax',
		Cargurus: 'cargurus',
		Echopark: 'echopark',
		Shift: 'shift',
		Givemethevin: 'givemethevin',
		Cashforcars: 'cashforcars',
		SellYourCar: 'sellyourcar',
		Peddle: 'peddle',
		Vincue: 'vincue',
		NorthWestMotorsport: 'northwestmotorsport'
	}
	
}