import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { connect } from 'react-redux'
type CustomDialogDto = {
	open: boolean;
	onClose: any;
	title?: string | null;
	content?: React.ReactNode | string | null;
	actions?: React.ReactNode | string | null;
	withPadding?: string;
	minWidth?: string;
	maxWidth?: string;
	minHeight?: string;
	scroll?: "paper" | "body" | undefined;
}
function CustomDialog(props: CustomDialogDto) {
	const {open, onClose, ...restProps} = props

	const dialogStyles = {
		'& .MuiDialog-paper': {
			border: 'none',
			borderRadius: '12px',
			padding: props.withPadding || {xs: '24px 16px', sm: '32px'},
			minWidth: props.minWidth || '304px',
			maxWidth: props.maxWidth || '529px',
			minHeight: props.minHeight || '400px',
			'& > .MuiDialogTitle-root': {
				padding: 0,
				fontSize: {xs: '18px', sm: '24px'},
				fontWeight: 600,
				marginBottom: restProps.scroll === 'paper' ? 0 : '16px',
			},
			'& > .MuiDialogContent-root': {
				padding: 0,
				fontSize: {xs: '14px', sm: '16px'},
				fontWeight: 400,
				lineHeight: '24px',
			},
			'& > .MuiDialogActions-root': {
				padding: 0,
			},
		},
	}

	return (
		<Dialog open={open} onClose={onClose} scroll={restProps.scroll} sx={dialogStyles}>
			{Boolean(props.title) && (
				<DialogTitle sx={{
					display: 'flex',
					justifyContent: 'center',
				}}>
					{props.title}
				</DialogTitle>
			)}
			<DialogContent>
				{props.content}
			</DialogContent>
			{Boolean(props.actions) && (
				<DialogActions sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: 2,
				}}>
					{props.actions}
				</DialogActions>
			)}
		</Dialog>
	)
}

const mapStateToProps = (state: any) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialog)