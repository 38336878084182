// import { AppBar, Box, Container, Grid, Toolbar } from '@mui/material';
import React from 'react';
import './main-layout.css';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import Header from '../header/Header';
import { Box } from '@mui/material';
// import BigFooter from '../footer/BigFooter';
// import {AppBar, Box, Container, Divider, Drawer, IconButton, Link, Toolbar, Typography} from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu'
// import LogoCarwiser from '../../img/logo-carwiser.svg'
import {Footer} from "../footer/Footer";
import Notification from "../notification/Notification";

//import {makeStyles} from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import SettingsDrawer from "../../components/SettingsDrawer";
// import {ReactComponent as MainLogo} from "../../logoSmall2.svg";
// import Grid from "@material-ui/core/Grid";
// import {Box} from "@material-ui/core";
// import DashboardFooter from "../DashboardFooter";
// import Support from "../Support";

// const useStyles = makeStyles((theme) => ({
//     box: {
//         marginLeft: '16px', marginRight: '16px', marginTop: '19px', width: '100%', paddingBottom: '100px'
//     },
// }));

class MainLayoutDto {
    children: any;
    prefixClass?: string;
}

export default function MainLayout(props: MainLayoutDto) {
    const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated, navigate]);

    const classes = ['main-content'];

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
            }}>
                {/*<Box>*/}
                    <Header/>
                {/*</Box>*/}
                <Box className={classes.join(' ')} sx={{
                    flex: '1 1 0%',
                    mt: {
                        xs: '62px',
                        md: '100px'
                    },
                    mb: '14px'
                }}>
                    {props.children}
                </Box>
                {/*<Box>*/}
                    <Footer/>
                {/*</Box>*/}
            </Box>
            <Notification/>
        </>
    )
}