import { ActionTypes } from '../actions/action-types'

export type ContactAgentState = {
	comment: {
		sent: boolean
		notification: {
			title: string
			content: string
		}
	}
}

const initialState: ContactAgentState = {
	comment: {
		sent: false,
		notification: {
			title: 'Thank you!',
			content: 'We’ve sent a notification to your Carwiser Agent, they will reach out to you as soon as possible.',
		}
	},
}

const reducer = (state: ContactAgentState = initialState, action: any): ContactAgentState => {
	switch (action.type) {
		case ActionTypes.SET_CONTACT_AGENT_DATA:
			return {
				...state,
				...action.data
			}
		default:
			return state
	}
}

export default reducer