import validate from 'validate.js'
import { AxiosResponse } from 'axios';
import {MainWrapperIdName} from "../constants";
const cloneDeep = require('lodash.clonedeep')

export const updateObject = (oldObject: any, updatedProperties: any) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};
/* Function to validate value according to rules passed
returns null or validation error message
 */
export const checkValidity = (value: any, rules: any) => {
    if (!rules) {
        return null;
    }
    return validate.single(value, rules) ?? null;
    // return validator.validate(value, rules);
}

const validateImage = (formState: any, value: any, controlName: string, e: any) => {
    const control = formState.controls[controlName];
    const validation = control.validation ? control.validation.image : null;
    const uploadedProps = e ? e : control.uploadedProps;
    if (!uploadedProps || !validation) {
        return null;
    }
    if (e) {
        control.uploadedProps = e;
    }
    let errorMessage = null;
    const size = uploadedProps.loaded / 1024 / 1024;
    if (validation.maxSize > 0 && validation.maxSize < size) {
        errorMessage = 'The image size shouldn\'t be greater than ' + validation.maxSize + ' MB';
    }

    return errorMessage;
}

export const isEmpty = (obj: any): boolean => {
    if (obj == null) return true;
    let length: number = 1;
    if (Array.isArray(obj) || typeof obj === 'string') {
        length = obj.length;
    } else if (typeof obj === 'object') {
        length = Object.values(obj).length;
    }
    return length === 0;
}

const validateField = (formState: any, value: any, controlName: string, event: any = null) => {
    if (controlName === 'phone') {
        // eslint-disable-next-line no-useless-escape
        value = value.replace(/[\._]/g, '')
    }
    let errorMessage: any;
    if (formState.controls[controlName].controlType === 'image' &&
        formState.controls[controlName].validation && formState.controls[controlName].validation.image) {
        return validateImage(formState, value, controlName, event);
    }
    if (!isEmpty(formState.controls[controlName].validation)) {
        value = trim(controlName, value)
        if (isPasswordTypeField(controlName) && value !== value.trim()) {
            return ['spaces at the beginning and the end are not allowed.']
        }
        if (formState.controls[controlName].validation.email && value === ''
            && formState.controls[controlName].validation.email.allowEmpty === true) {
            return
        }
        if (formState.controls[controlName].validation.length && value === ''
            && formState.controls[controlName].validation.length.allowEmpty === true) {
            return
        }
        if (formState.controls[controlName].validation.numericality && value === ''
            && formState.controls[controlName].validation.numericality.allowEmpty === true) {
            return
        }

        const validationSingle = {...formState.controls[controlName].validation}
        'equality' in validationSingle && delete validationSingle.equality
        errorMessage = checkValidity(value, validationSingle)

        const validationEquality = formState.controls[controlName].validation.equality
        if ([undefined, null].includes(errorMessage) && validationEquality) {
            const compareToField = validationEquality
            const compareToFieldAttr = typeof compareToField === 'string' ? compareToField : compareToField.attribute;
            const constraints = {
                [controlName]: {
                    equality: compareToField
                }
            }
            errorMessage = validate({
                [controlName]: value,
                [compareToFieldAttr]: formState.controls[compareToFieldAttr].value
            }, constraints)

            if (errorMessage && errorMessage[controlName]) {
                errorMessage = errorMessage[controlName]
            }
        }
    }

    errorMessage = Array.isArray(errorMessage) ? errorMessage.filter(error => !['function'].includes(typeof error)).join(', ') : errorMessage

    return errorMessage
}

// todo:
// validate.validators.passwordStrength = (value: any, options: any, key: string, attributes: any) => {

//     const controlName = '__password_field';
//     const pattern = options.pattern ? options.pattern : new RegExp('([a-z]+\d+)|(\d+[a-z]+)', 'i');
//     let errorMessage = validate({
//         [controlName]: value
//     }, {
//         [controlName]: {
//             format: {
//                 pattern: pattern,
//                 message: options.message ? options.message : () => {
//                     return validate.format("Invalid strength. You can use lower case (a-z), upper case (A-Z) and numbers (0-9)");
//                 }
//             }
//         }
//     });

//     if (errorMessage && errorMessage[controlName]) {
//         errorMessage = String(errorMessage[controlName]).replace(controlName, '');
//     }

//     return errorMessage;
// };

export const inputChangedHandler = (formState: any, setFormState: any, value: any,
    controlName: string, needValidation = false, event = null, dependencyControls?: string[]) => {

    const updatedState = cloneObject(formState);
    const field = updatedState.controls[controlName];

    if (dependencyControls && Array.isArray(dependencyControls)) {
        dependencyControls.forEach((el) => {
            updatedState.controls[el].value = updatedState.controls[el]?.hasOwnProperty('defaultValue') ? updatedState.controls[el]['defaultValue'] : ''
            updatedState.controls[el].touched = false
            updatedState.controls[el].valid = true
            updatedState.controls[el].helperText = ''
        })
    }

    if (field.hasOwnProperty('inputFilter')) {
        const regExp = new RegExp(field.inputFilter, 'g')
        value = value.replace(regExp, '');
    }

    field.value = value;
    field.touched = true;

    if (needValidation) {
        let errorMessage = validateField(formState, value, controlName, event);
        field.valid = !errorMessage;
        if (errorMessage && field.hasOwnProperty('label')) {
            errorMessage = field.label + ' ' + errorMessage
        }
        field.helperText = errorMessage;
    }

    const isFormValid = Object.entries(updatedState.controls).every((element: any) => element[1].valid);
    updatedState.valid = isFormValid;
    updatedState.touched = true;

    setFormState((prevFormState: any) => {
        return {
            ...prevFormState,
            ...updatedState,
        }
    });
}

export const validateForm = (formState: any, setFormState: any, excludeFields: string[] = [], updateState: boolean = true) => {
    const updatedState = cloneObject(formState)
    for (let key in updatedState.controls) {
        if (excludeFields.find((item) => item === key)) {
            continue
        }
        const field = updatedState.controls[key]

        if (typeof field.value === 'string') {
            field.value = field.value.trim()
        }

        let errorMessage = validateField(formState, field.value, key)

        field.valid = !errorMessage
        field.touched = true

        if (errorMessage && field.hasOwnProperty('label')) {
            errorMessage = field.label + ' ' + errorMessage
        }

        field.helperText = errorMessage
    }
    const isFormValid = Object.entries(updatedState.controls).filter(element => !excludeFields.includes(element[0] as never)).every(element => (element[1] as any).valid)
    updatedState.valid = isFormValid
    updatedState.touched = true
    if (updateState) {
        setFormState({...formState, ...updatedState})
    }
    return isFormValid
}

export const prepareFormData = (formData: any, state: any = null) => {
    const formDataUpdated = cloneObject(formData)
    for (let key in formDataUpdated.controls) {
        const field = formDataUpdated.controls[key]
        field.key = key;
        if (state?.hasOwnProperty(key)) {
            field.value = (state[key]) ?? ''
            if(field.value) {
                field.valid = true
            }
            field.touched = field.value ? true : false
        } else {
            field.touched = false
        }
        field.helperText = null
    }
    return formDataUpdated;
}

export const getFormData = (formData: any) => {
    const data: any = {};
    for (let key in formData.controls) {
        let value = formData.controls[key].value;
        value = trim(key, value)
        data[key] = value
    }
    return data;
}

const trim = (key: string, value: any) => {
    if (typeof value === 'string' && !isPasswordTypeField(key)) {
        return value.trim()
    }
    return value
}

const isPasswordTypeField = (key: string) => {
    return ['password', 'repassword', 'newPassword', 'oldPassword', 'confirmPassword'].includes(key)
}

export const cloneObject = (obj: any) => {
    return cloneDeep(obj);
}

export const validateResponse = (response: AxiosResponse) => {
    return (response.status === 200 && !response.data.error) ? true : false
}

export const getResponseError = (response: AxiosResponse) => {
    if (response.status === 200 && !response.data && !response.data.error) {
        return null;
    }

    return response.data.error ? response.data.error : 'Request failed';
}

export const isRetinaScreen = () => {
    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
}

export const copyObject = (obj: any): any => {
    return Array.isArray(obj) ?
        [].concat(obj.map((item: any) => copyObject(item)) as any) :
        Object.assign({}, obj);
}

export const phoneFormatter = (phoneNumber: string, regex = /(\d{3})(\d{3})(\d{4})/giu, replaceValue = "($1) $2-$3"): string => {
    return phoneNumber.replace(regex, replaceValue)
}

export const camelToSnakeCase = (inString: string) => {
    return inString
        .replaceAll(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
}

export const sanitizeString = (inString: string) => {
    return inString
        .replaceAll(/\s\-\s/gi, '-')
        .replaceAll(/\//gi, '-')
        .replaceAll(/[^\w\s\-]/gi, '')
        .replaceAll(/\s+/gi, '-')
        .toLowerCase()
}

type GenerateTagOptions = {
    mainPrefix?: string
    prefix?: string
    snippet?: string
    suffix?: string
}

export const generateTag = (name: string, options: GenerateTagOptions | null = null) => {
    const mainPrefix = options?.mainPrefix ? `${options.mainPrefix}` : MainWrapperIdName
    const prefix = options?.prefix ? `-${camelToSnakeCase(options.prefix)}` : ''

    const tag = `-${sanitizeString(camelToSnakeCase(name))}`

    const snippet = options?.snippet ? `-${options.snippet}` : ''
    const suffix = options?.suffix ? `-${options.suffix}` : ''

    return `${mainPrefix}${prefix}${tag}${snippet}${suffix}`.toLowerCase()
}