export const PrimaryNamesType = {
    Default : 'default',

    //used clients
    Carvana : 'carvana',
    Vroom : 'vroom',
    Carmax : 'carmax',
    Cargurus : 'cargurus',
    Echopark : 'echopark',
    Shift : 'shift',
    Givemethevin : 'givemethevin',
    Cashforcars : 'cashforcars',
    SellYourCar : 'sellyourcar',
    Peddle : 'peddle',
    Vincue : 'vincue',
    WeBuyAnyCar : 'webuyanycar',
    NorthWestMotorsport : 'northwestmotorsport',
}

export const AffiliateProgram = {
    carvana: {
        name: 'carvana-AfPr',
    }
}

export const Types = {
    Default: 0,
    Auto: 1,
    Autonation: 2,
    Manual: 3,
    Vincue: 4,
    IOC: 5,
};