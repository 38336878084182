import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/action-types";

export class RefreshOffersState {
    step: 'form'|'loading'|'fail' = 'form' ;
}

const initialState: RefreshOffersState = new RefreshOffersState();

const setStep = (state: RefreshOffersState, action: RefreshOffersState) => {
    const updatedState: RefreshOffersState = JSON.parse(JSON.stringify(state));
    updatedState.step = action.step;
    return updatedState;
}

const setStateLoading = (state: any, action: any) => {
    return updateObject(state, {
        loading: !!action.loading
    });
}

const reducer = (state: RefreshOffersState = initialState, action: any): RefreshOffersState => {
    switch (action.type) {
        case ActionTypes.REFRESH_OFFERS_SET_STEP:
            return setStep(state, action);
        case ActionTypes.REFRESH_OFFERS_SET_LOADING:
            return setStateLoading(state, action);
        default:
            return state;
    }
};

export default reducer;