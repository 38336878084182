import {updateObject} from "../../shared/utility";
import { ActionTypes } from "../actions/action-types";
import { Vehicle } from "../actions/vehicles";

export class VehiclesState {
    isInited: boolean = false;
    selected?: Vehicle;
    selectedIdx?: number;
    list: Vehicle[] = [];
};

const initialState: VehiclesState = new VehiclesState();

const setData = (state: VehiclesState, action: any) => {
    const updatedState: VehiclesState = JSON.parse(JSON.stringify(state));
    updatedState.list.push(action.data);
    return updatedState
}

const setInited = (state: VehiclesState, action: any) => {
    const updatedState: VehiclesState = JSON.parse(JSON.stringify(state));
    updatedState.isInited = !!action.isInited;
    return updatedState
}

const loadData = (state: VehiclesState, action: { list: Vehicle[] }) => {
    const updatedState: VehiclesState = JSON.parse(JSON.stringify(state));
    updatedState.list = action.list;
    return updatedState;
}

const saveVehicle = (state: VehiclesState, action: any) => {
    const updatedState: VehiclesState = JSON.parse(JSON.stringify(state));
    updatedState.list[action.data.id] = updateObject(updatedState.list[action.data.id], action.data.data)
    return updatedState
}

const setSelectedVehicle = (state: VehiclesState, action: { index: number, vehicles?: Vehicle[] }) => {
    const updatedState: VehiclesState = JSON.parse(JSON.stringify(state));
    updatedState.selectedIdx = action.index;
    updatedState.selected = action.vehicles ? action.vehicles[action.index] : undefined;
    return updatedState;
}


const reducer = (state: VehiclesState = initialState, action: any): VehiclesState => {
    switch (action.type) {
        case ActionTypes.VEHICLES_ADD_VEHICLE:
            return setData(state, action);
        case ActionTypes.VEHICLES_SAVE_VEHICLE:
            return saveVehicle(state, action);
        case ActionTypes.VEHICLES_LOAD_VEHICLE:
            return loadData(state, action);
        case ActionTypes.VEHICLES_SET_SELECTED:
            return setSelectedVehicle(state, action);
        case ActionTypes.VEHICLES_SET_INITED:
            return setInited(state, action);
        default:
            return state;
    }
};

export default reducer;