declare global {
	interface Window {
		heap: any;
	}
}

class HeapHelper {
	static load = (callback: () => void) => {
		if(!process.env.REACT_APP_HEAP_APP_ID) return
		const existingScript = document.getElementById('heap');
		if (!existingScript) {
			if (!window.heap) {
				window.heap = []
				window.heap.load = (e: any, t: any) => {
					window.heap.appid = e
					window.heap.config = t ? t : {}
					const r = document.createElement("script");
					r.id = 'heap'
					r.type = "text/javascript"
					r.async = !0
					r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
					const a = document.getElementsByTagName("script")[0];
					a.parentNode?.insertBefore(r, a);
					for (let n = function (e: any) {
						return function () {
							window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
						}
					}, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++) window.heap[p[o]] = n(p[o])
				}
			}

			window.heap.load(process.env.REACT_APP_HEAP_APP_ID, {
				secureCookie: true
			});
		}
		if (existingScript && callback) callback();
	}
}

export default HeapHelper