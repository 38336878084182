import React from 'react'
import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { RootState } from '../../app/store'
import { Vehicle } from '../../store/actions/vehicles'
import { NumberFormatter } from '../../shared/formatter'
import VehicleDetailItem from "./VehicleDetailItem";
import VehicleImageBlock from './VehicleImageBlock'
import VehicleName from "./VehicleName";

class VehicleDto {
	vehicle?: Vehicle;
	loading?: boolean;
	// offers?: any[];
}

function VehicleComponent(props: VehicleDto) {
	const vehicle = props.vehicle;
	const mileage = vehicle ? NumberFormatter.format(vehicle.mileage) : null;

	if (props.loading === true) {
		return (
		<React.Fragment>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={true}
				>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>);
	}

	if (vehicle?.vin === null || !vehicle?.vin) {
		return (<React.Fragment></React.Fragment>)
	}

	return (
		<Stack direction={{ xs: 'column', md: 'row' }} sx={{
			mb: 1,
			borderRadius: '24px',
			border: '3px solid #808080',
			p: {xs: '20px', sm: '90px 80px 40px'},
			textAlign: 'left',
		}}>
			{(vehicle?.vehicleImage?.mobile && vehicle?.vehicleImage?.desktop) && (<Box sx={{
				marginRight: {md: 0, lg: 10},
			}}>
				<VehicleImageBlock image={vehicle?.vehicleImage}/>
			</Box>)}
			<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
				<VehicleName vehicle={vehicle}/>
				<Typography sx={{
					fontSize: {xs: '16px', sm: '20px'},
					lineHeight: {xs: '24px', sm: '28px'},
					fontWeight: {xs: 600, sm: 500},
					textTransform: 'uppercase',
					mb: '20px',
				}}>
					{vehicle?.trim}
				</Typography>
				<VehicleDetailItem label={'vin'} value={vehicle?.vin}/>
				<VehicleDetailItem label={'mileage'} value={mileage}/>
				<VehicleDetailItem label={'engine'} value={vehicle?.engine}/>
				<VehicleDetailItem label={'transmission'} value={vehicle?.transmission}/>
				<VehicleDetailItem label={'drivetrain'} value={vehicle?.drivetrain}/>
			</Box>
		</Stack>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.app.loading,
		vehicle: state.vehicles.selected,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleComponent)