import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import React, {useState} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import { Button } from '../../components/form/Button';
import { FormTextField } from '../../components/form/TextField';
import * as actions from "../../store/actions";
import {prepareFormData, validateForm} from "../../shared/utility";
import './MyAccount.css';
import {validationConfirmPassword, validationPassword} from "../../shared/validationRules";

let formData = {
    controls: {
        password: {
            label: 'New Password',
            value: '',
            validation: validationPassword,
            helperText: '',
            valid: false,
            touched: false,
            showPassword: false,
        },
        confirmPassword: {
            label: 'Re-Type Password',
            value: '',
            validation: validationConfirmPassword,
            helperText: '',
            valid: false,
            touched: false,
            showPassword: false,
        }
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

class ChangePasswordProps {
    user: any;
    onChangePassword: any;
}

const ChangePasswordComponent = (props: ChangePasswordProps) => {

    formData = prepareFormData(formData);
    const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [formState, setFormState] = useState(formData);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const submitHandler = async (event: any) => {
        event.preventDefault();
        if (validateForm(formState, setFormState)) {
            const password = formState.controls.password.value;
            setLoading(true);
            props.onChangePassword(props.user, password, (err: any, res: any) => {
                setLoading(false);
                if (err) {
                    return ;
                }
               navigate('/dashboard');
            })
        }
    }

    const confirmPasswordField = formState.controls.confirmPassword;
    const passwordField = formState.controls.password;


    return (
        <Container className={'cntx-container'} maxWidth="lg" disableGutters={isMobile ? false : true}>
            <Box>
                <h1 className={'title'}>Settings</h1>
                <h3 className={'title'}>Change Password</h3>
                <form noValidate onSubmit={submitHandler}>
                    <Box className={'fields'}>
                        <div className={'row'}>
                            <FormTextField
                                field={passwordField}
                                type={passwordField.showPassword ? 'input' : 'password'}
                                formState={formState}
                                setFormState={setFormState}
                                />
                        </div>
                        <div className={'row'}>
                            <FormTextField
                                field={confirmPasswordField}
                                type={confirmPasswordField.showPassword ? 'input' : 'password'}
                                formState={formState}
                                setFormState={setFormState}
                            />
                        </div>
                    </Box>

                    {/* {props.error &&
                    <div className={`$classes.wrapper `}>
                        <Alert severity="error">{props.error.message ?? ''}</Alert>
                    </div>
                    } */}

                    <Box className={'buttons'}>
                        <Button className={'first'} variant={'outlined'} onClick={() => {
                            navigate('/dashboard')
                        }}
                        > Cancel</Button>

                        <Button type={"button"} loading={loading} onClick={submitHandler}
                        > Save</Button>

                    </Box>
                </form>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.auth.user,
        error: state.auth.error,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onChangePassword: (user: any, password: string, cb: any) => dispatch(actions.changePassword(user, password, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordComponent);