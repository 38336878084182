import React from 'react'
import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import { connect } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { RootState } from '../../app/store'
import { Vehicle } from '../../store/actions/vehicles'

class TitleDto {
	app: any;
	appError?: any;
	offers?: any[];
	vehicle?: Vehicle;
}

function Title(props: TitleDto) {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const offers = props.offers;
	const vehicle = props.vehicle;

	let title
	if (props.appError || !vehicle?.vin) {
		title = 'Something went wrong'
	} else if (offers?.length) {
		title = offers?.length === 1
		? `We found ${offers?.length} offer for you`
		: `We found ${offers?.length} offers for you`;
	} else {
		title = 'No current offers found';
	}

	return (
		<Box sx={{backgroundColor: '#ffffff'}}>
			<Container maxWidth="lg" disableGutters={isMobile ? false : true}>
				<Box sx={{
					mt: {xs: '16px', sm: '40px'},
					mb: {xs: '16px', sm: '20px'},
					height: {xs: '28px', sm: '40px'},
					display: 'flex',
					justifyContent: 'space-between',
				}}>
					{(props.appError || !props.app.loading) && (
						<Box sx={{
							display: 'flex',
							alignItems: 'center'
						}}>
							<Typography variant="h3" sx={{
								fontSize: {xs: '18px', sm: '36px'},
								lineHeight: {xs: '28px', sm: '40px'},
								fontWeight: {xs: 600},
								color: '#2A2F31',
								position: 'relative',
							}}>
								{title}
								{Boolean(props.offers?.length) && <Box sx={{
									position: 'absolute',
									right: '-12px',
									top: '2px',
									display: 'inline',
									fontSize: '24px',
									lineHeight: '32px',
									fontWeight: 400,
									color: '#2BACE2',
									textAlign: 'center',
								}}>*</Box>}
							</Typography>
						</Box>
					)}
				</Box>
			</Container>
		</Box>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		app: state.app,
		appError: null,
		vehicle: state.vehicles?.selected,
		offers: state.vehicles?.selected?.offers,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Title)