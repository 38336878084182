import React from 'react'
import { Box, Container, Link, Stack } from '@mui/material'

import FacebookLogo from '../../img/footer/facebook.svg'
import TwitterLogo from '../../img/footer/twitter.svg'
import InstagramLogo from '../../img/footer/instagram.svg'
import YouTubeLogo from '../../img/footer/youtube.svg'

import moment from 'moment'

class ImgItemDto {
	link!: string;
	sourceLogo!: string;
}

function ImgItem(props: ImgItemDto) {
	return (
		<Link href={props.link} sx={{
			width: {xs: '36px', sm: '48px'},
			height: {xs: '36px', sm: '48px'},
			backgroundImage: `url(${props.sourceLogo})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain',
		}} target="_blank" rel="noopener noreferrer"/>
	)
}

export function Footer() {
	return (
		<React.Fragment>
			{/*<Providers/>*/}
			<Box className="footer" sx={{
				pt: 3,
				pb: 3,
				backgroundColor: '#3B464A',
				color: '#B3B3B3',
				'& a': {
					color: '#B3B3B3',
					textDecoration: 'none',
				},
				'& a:hover': {
					color: '#B3B3B3',
					textDecoration: 'none',
				},
				fontWeight: 400,
				fontSize: {xs: '12px', sm: '14px'}
			}}>
				<Container maxWidth="lg" disableGutters={true}>
					<Stack direction={{xs: 'column', sm: 'row'}} alignItems="center" justifyContent="space-between" sx={{}}>
						<Box sx={{display: 'flex', flexDirection: 'column', textAlign: {xs: 'center', sm: 'left'}}}>
							<Box>Contact Us:</Box>
							<Box>Support@carwiser.com</Box>
							<Box>(619) 343-3729</Box>
						</Box>
						<Box sx={{display: 'flex', flexDirection: 'row', mt: {xs: 2, sm: 0}}}>
							<Stack direction="row" spacing={2}>
								<ImgItem sourceLogo={FacebookLogo} link="https://www.facebook.com/Carwiser-107279791047030"/>
								<ImgItem sourceLogo={TwitterLogo} link="https://twitter.com/sellcarwiser"/>
								<ImgItem sourceLogo={InstagramLogo} link="https://instagram.com/SellCarwiser"/>
								<ImgItem sourceLogo={YouTubeLogo} link="https://www.youtube.com/channel/UCs9hoanaNhxBNnKQGhQkr6w"/>
							</Stack>
						</Box>
					</Stack>
					<Stack direction={{xs: 'column', sm: 'row'}} alignItems="center" justifyContent="space-between" sx={{mt: 2}}>
						<Box sx={{
							textAlign: {xs: 'center', sm: 'left'},
						}}>
							&copy; Copyright {moment().format('YYYY')} by <Link href="https://carwiser.com" target="_blank" rel="noopener noreferrer">Carwiser</Link>. All rights reserved.
						</Box>
						<Link href="https://carwiser.com" target="_blank" rel="noopener noreferrer" sx={{
							mt: {xs: 2, sm: 0},
							width: {xs: '155px', sm: '175px'},
							height: {xs: '32px', sm: '36px'},
							backgroundImage: `url(https://images.carwiser.com/carwiser/Carwiser_PoweredBy_BW_w_Car.svg)`,
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundSize: 'contain',
						}}/>
					</Stack>
				</Container>
			</Box>
		</React.Fragment>
	)
}