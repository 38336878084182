import './Header.css';
import MainLogo from '../logo/MainLogo';
import {Box, Toolbar} from '@mui/material';
import SettingsDrawer from '../drawer/SettingsDrawer';

function Header() {
    return (
      <Box className='header-wrapper' sx={{
          '&.header-wrapper': {
              height: {xs: '62px', md: '100px'},
              zIndex: 1100,
          }
      }}>
        <Box className='logo-wrapper' sx={{
            '&.logo-wrapper': {
                paddingLeft: {xs: '16px', md: '40px'},
            },
        }}>
          <MainLogo/>
        </Box>
        <Toolbar>
          <section>
            <SettingsDrawer/>
          </section>
        </Toolbar>
      </Box>
    );
  }

export default Header;