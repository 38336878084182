import React, { useEffect, useState } from 'react';
import './RefreshOffers.css'
import headset from "../../../img/headset.svg";
import listAlt from "../../../img/list-alt.svg";
import { Link } from '@mui/material'
import { Button } from '../../form/Button';
import {generateTag, phoneFormatter} from "../../../shared/utility";

const phone = process.env.REACT_APP_PHONE_DETAILS?.toString() ?? ''

const RefreshOffersFail = (props : {onClose:any}) => {

    useEffect(() => {
        document.getElementsByClassName("refresh-offers-container")[0]?.scrollIntoView();
    }, []);

    return (
        <div className='refresh-offers-container'>
            <div className='title'>UH OH!</div>
            <div className='content'>
                <div className='top-align'>
                    <div className='subheader'>It looks like we need some more information to refresh your offers. While we get you some help, you have a couple other options:</div>
                    <div className='contact-block'>
                        <div className='support'>
                            <div className='call-title-block'>
                                <img className='call-img' src={headset} alt="call img" />
                                <div className='call-title'>CALL FOR SUPPORT</div>
                            </div>
                            <div className='call-subtitle-block'>
                                <div className='call-subtitle'>Call an available agent now for instant support. </div>
                                <a className="call-phone" href={`tel:+1${phone}`}>
                                    {phoneFormatter(phone)}
                                </a>
                            </div>
                        </div>
                        <div className='divider' />
                        <div className='details'>
                            <div className='details-title-block'>
                                <img className='details-img' src={listAlt} alt="details img" />
                                <div className='details-title'>UPDATE DETAILS</div>
                            </div>
                            <div className='head'>Head to
                                <Link className="carwiser-link" underline="none" href="http://www.carwiser.com/" target="_blank"> Carwiser.com </Link>
                                 to update your vehicle details and get new offers.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='button'>
                    <Button
                        onClick={props.onClose}
                        paddingLeft="75px"
                        id={generateTag('close', {prefix: 'modal-refresh-offers-form-fail', suffix: 'btn'})}
                    >
                        CLOSE
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RefreshOffersFail