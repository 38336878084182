class VwoHelper {
    static load = (callback) => {
        if(!process.env.REACT_APP_VWO_ID) return
        const existingScript = document.getElementById('_vwo_code');
        if (!existingScript) {
            if(!window._vwo_code) {
                window._vwo_code=window._vwo_code || (function() {
                    let settings_timer
                    window._vwo_code = undefined
                    let f =false
                    const account_id=process.env.REACT_APP_VWO_ID,
                        version=1.3,
                        settings_tolerance=2000,
                        library_tolerance=2500,
                        use_existing_jquery=false,
                        is_spa=1,
                        hide_element='body',
                        d=document,code={
                            use_existing_jquery:function(){return use_existing_jquery},
                            library_tolerance:function(){return library_tolerance},
                            finish:function(){
                                if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}
                            },
                            finished:function(){
                                return f
                            },
                            load:function(e){
                                const t=d.createElement('script');
                                t.fetchPriority='high';
                                t.src=e;
                                t.id = 'vwoCode';
                                t.type='text/javascript';
                                t.innerText=undefined;
                                t.onerror=function(){window._vwo_code.finish()};
                                d.getElementsByTagName('head')[0].appendChild(t)
                            },
                            init:function(){
                                console.log('init')
                                window.settings_timer=setTimeout(function(){
                                    console.log('timer', window._vwo_code)
                                    window._vwo_code?.finish()
                                },settings_tolerance);
                                const e=d.createElement('style'),
                                    t=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',
                                    i=d.getElementsByTagName('head')[0];
                                e.setAttribute('id','_vis_opt_path_hides');
                                e.setAttribute('nonce',document.querySelector('#vwoCode')?.nonce);
                                e.setAttribute('type','text/css');
                                if(e.styleSheet)e.styleSheet.cssText=t;
                                else e.appendChild(d.createTextNode(t));i.appendChild(e);
                                this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version);
                                return settings_timer}}
                    ;window._vwo_settings_timer = code.init();
                    return code;}())
            }
        }
        if (existingScript && callback) callback();
    }
}

export default VwoHelper