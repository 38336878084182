import axios, { AxiosRequestConfig } from 'axios';
import {errorInterceptor} from './shared/error-handler';
import {AuthTokenDto, TokenStorage} from './shared/auth-utility';

const baseURL  = String(process.env.REACT_APP_CARWISER_API_URL);

const instance = axios.create({
    baseURL
});

instance.interceptors.response.use(
    res => res,
    err => {
        errorInterceptor(err);
    }
  )

instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = TokenStorage.get() as AuthTokenDto;
    if (config && config.url && (config.url.indexOf(baseURL) > -1 || config.url.indexOf('http') === -1) && token && token.id_token) {
        config.headers || (config.headers = {});
        config.headers['Authorization'] || (config.headers['Authorization'] = [token.type, token.id_token].join(' '));
        config.headers['Content-Type'] || (config.headers['Content-Type'] = 'application/json');
    }

    return config;
});

export default instance;
