import {ActionTypes} from "./action-types"
import {Offer} from "./offers";
import axios from "../../axios";
import {AxiosResponse} from "axios";

const validateResponse = (response: AxiosResponse, validStatus: number = 200) => {
	return (response.status === validStatus && !response.data.error) ? true : false
}

export function sendCommentToContactAgent(offer: Offer | null = null) {
	return async (dispatch: any, getState: any) => {
		const state = getState()

		dispatch({type: ActionTypes.SET_APP_DATA, data: {loading: true}})

		try {
			if (!state.vehicles?.selected?.code) {
				throw new Error(`Selected vehicle does not have a code`)
			}

			let url = `/contacts/${state.vehicles.selected.code}`

			const response = await axios.post(url, {
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (!validateResponse(response, 201)) {
				throw new Error(`Request failed: ${response.data.message}`)
			}

			dispatch({type: ActionTypes.SET_CONTACT_AGENT_DATA, data: {comment: {...state.contactAgent.comment, sent: true}}})
		}
		catch (e: any) {
			dispatch({type: ActionTypes.SET_APP_DATA, data: {error: e.message}})
		}

		dispatch({type: ActionTypes.SET_APP_DATA, data: {loading: false}})
	}
}