import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { Auth0Config } from '../config/auth0';
import { rootReducer } from '../store/reducers';
// import { composeWithDevTools } from '@redux-devtools/extension';

Auth0Config.configure();

// devToolsEnhancerLogOnlyInProduction
// const composeEnhancers = composeWithDevTools(
  // applyMiddleware(...middleware)
  // other store enhancers if any
// );

export const store = configureStore({
  reducer: rootReducer,
  // enhancers: [composeEnhancers],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
