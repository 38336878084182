import React from 'react';
import './RefreshOffers.css'
import RefreshOffersForm from "./RefreshOffersForm";
import CustomDialog from "../CustomDialog";
import {RootState} from "../../../app/store";
import {connect} from "react-redux";
import RefreshOffersFail from "./RefreshOffersFail";
import RefreshOffersLoading from "../RefreshOffersLoading/RefreshOffersLoading";

const RefreshOffers = (props: { open: boolean; onClose: any; step: string }) => {

    let dialogContent

    switch (props.step) {
        case 'form' :
            dialogContent = <RefreshOffersForm onClose = {props.onClose}/>
            break;
        case 'loading': {
            dialogContent = <RefreshOffersLoading/>
            break;
        }
        case 'fail': {
            dialogContent = <RefreshOffersFail onClose = {props.onClose}/>
            break;
        }
    }
    return <CustomDialog
        open={props.open}
        onClose={props.onClose}
        scroll={'paper'}
        content={dialogContent}
        withPadding={'0px'}
    />
}

const mapStateToProps = (state: RootState) => {
    return {
        step: state.refreshOffers.step,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshOffers)

