import React from 'react';
import {connect} from 'react-redux';
import './SettingsDrawer.css';
import { useNavigate } from 'react-router-dom';
import { Box, ClickAwayListener, Divider, Drawer, IconButton,
    List, ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { RootState } from '../../app/store';
import {ReactComponent as MenuIcon} from  "../../img/menu.svg";
import {ReactComponent as HelpActionIcon} from "../../img/action-help.svg";
import {ReactComponent as HomeActionIcon} from "../../img/action-home.svg";
import {ReactComponent as MyAccountActionIcon} from "../../img/action-my-account.svg";
import {ReactComponent as SettingsActionIcon} from "../../img/action-settings.svg";
import {ReactComponent as LogoutActionIcon} from "../../img/action-logout.svg";
import DrawerFooter from './DrawerFooter';
import { logout } from '../../store/actions';
import {generateTag} from "../../shared/utility";

const SettingsDrawer = (props: any) => {


    const classes = {
        avatar: undefined,
        drawer: undefined,
        icon: undefined,
        IconButton: undefined,
    };
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    const logoutHandler = () => {
        props.onLogout((redirect: string) => {
            if (redirect)
                navigate(redirect)
        });
    }

    const changeMode = () => {
        props.onChangeDarkMode(!props.darkMode)
    };
    const userName = [];
    if (props.account && props.account.firstName) {
        userName.push(props.account.firstName);
    }
    if (props.account && props.account.lastName) {
        userName.push(props.account.lastName);
    }

    const list = (
        <ClickAwayListener onClickAway={toggleDrawer(false)}>
            <div
                className={"drawer-list"}
                role="presentation"
                onKeyDown={toggleDrawer(false)}

            >
                <div className={'drawer-main'}>
                    <div className={'drawer-header'}>
                        {/* <Avatar>{(props.account && props.account.firstName) ? capitalize(props.account.firstName).charAt(0) : "D"}</Avatar> */}
                        <Box className={"username"}>{userName.length > 0 ? userName.join(' ') : ''}</Box>
                        <Box className={"email"}>
                            {(props.account && props.account.email) ? props.account.email : ''}
                        </Box>
                    </div>

                    <Divider/>
                    <List className={"main-actions"}>
                        {false && <ListItem button>
                            <ListItemIcon style={{marginLeft: '-16px', marginRight: '16px'}}><Switch
                                checked={props.darkMode}
                                onChange={changeMode}
                                color={"primary"}
                                name="checkedA"
                                inputProps={{'aria-label': 'primary checkbox'}}

                            /></ListItemIcon>
                            <ListItemText primary={'Dark Mode'}/>
                        </ListItem>
                        }

                        {true && (
                            <ListItem button onClick={() => {
                                setOpen(false);
                                navigate('/why-carwiser');
                            }}>
                                <ListItemIcon><HelpActionIcon className={"drawer-icon"}/></ListItemIcon>
                                <ListItemText primary={'Why Carwiser?'}/>
                            </ListItem>
                        )}

                        <ListItem button onClick={() => {
                            setOpen(false);
                            navigate('/dashboard');
                        }}>
                            <ListItemIcon><HomeActionIcon className={"drawer-icon"}/></ListItemIcon>
                            <ListItemText primary={'My Dashboard'}/>
                        </ListItem>

                        {/* <ListItem button onClick={() => {
                            setOpen(false);
                            navigate('/dashboard/vehicle');
                        }}>
                            <ListItemIcon><DriveEtaIcon className={"drawer-icon"} fontSize={'small'}/></ListItemIcon>
                            <ListItemText primary={'My Vehicles'}/>
                        </ListItem> */}

                    </List>
                    <Divider/>
                    <List className={"main-actions"}>
                        {
                            <ListItem
                                button
                                onClick={() => {
                                    setOpen(false);
                                    navigate('/account');
                                }}
                                id={generateTag('my-account', {prefix: 'main-sidebar', suffix: 'link'})}
                            >
                                <ListItemIcon><MyAccountActionIcon className={"drawer-icon"}/></ListItemIcon>
                                <ListItemText primary={'My Account'}/>
                            </ListItem>
                        }

                        {
                            <ListItem
                                button
                                onClick={() => {
                                    setOpen(false);
                                    navigate('/account/settings');
                                }}
                                id={generateTag('settings', {prefix: 'main-sidebar', suffix: 'link'})}
                            >
                                <ListItemIcon><SettingsActionIcon className={"drawer-icon"}/></ListItemIcon>
                                <ListItemText primary={'Settings'}/>
                            </ListItem>
                        }

                        <ListItem button onClick={logoutHandler}>
                            <ListItemIcon><LogoutActionIcon className={"drawer-icon"} fontSize={'small'}/></ListItemIcon>
                            <ListItemText primary={'Log Out'}/>
                        </ListItem>

                    </List>
                </div>
                <DrawerFooter/>
            </div>
        </ClickAwayListener>
    );

    return (
        <React.Fragment>
            <IconButton
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                className="drawer-menu"
            >
                <MenuIcon />
            </IconButton>
            <div>
                <Drawer
                    anchor={'right'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    classes={{ paperAnchorRight: classes.drawer }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            minWidth: {xs: '300px', md: '365px'},
                        }
                    }}
                >
                    {list}
                </Drawer>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        darkMode: state.app.darkMode,
        account: state.account,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        // onChangeDarkMode: (state: any) => dispatch(setDarkMode(state)),
        onLogout: (onRedirect: any) => dispatch(logout(onRedirect))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDrawer);