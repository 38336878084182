export const validationVin = {
	presence: {
		allowEmpty: false,
	},
	length: {
		minimum: 17,
		maximum: 17,
	},
	format: {
		pattern: '[A-HJ-NPR-Za-hj-npr-z0-9]+',
		flags: 'gi',
	},
}
export const validationName = {
	presence: {
		allowEmpty: false,
	},
	length: {
		minimum: 2,
		maximum: 30,
		message: function (value, attribute, validatorOptions, attributes, globalOptions) {
			if (!value) {
				return
			}
			let message
			if (validatorOptions.minimum && value.length < validatorOptions.minimum) {
				message = 'is too short (minimum is %{count} characters)'.replace('%{count}', validatorOptions.minimum)
			}
			else if (validatorOptions.maximum && value.length > validatorOptions.maximum) {
				message = 'is too long (maximum is %{count} characters)'.replace('%{count}', validatorOptions.maximum)
			}
			return message
		},
	},
	format: {
		pattern: '[a-zA-Z ]+',
		flags: 'giu',
		message: function (value, attribute, validatorOptions, attributes, globalOptions) {
			if (!value) {
				return
			}
			const message = 'can contain only latin symbols'
			return message
		},
	},
}

export const validationLastName = {
	...validationName,
	...{
		format: {
			pattern: '[a-zA-Z- \']+',
			flags: 'giu',
			message: function (value, attribute, validatorOptions, attributes, globalOptions) {
				if (!value) {
					return
				}
				const message = 'can contain only latin symbols'
				return message
			},
		},
	}
}

export const validationEmail = {
	presence: {allowEmpty: false},
	format: {
		pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		message: function (value, attribute, validatorOptions, attributes, globalOptions) {
			if (!value) {
				return
			}
			const message = 'is not valid'
			return message
		},
	},
}

export const validationPhone = {
	presence: {
		allowEmpty: false,
	},
	format: {
		pattern: '\\(\\d{3}\\)\\s\\d{3}-\\d{4}|\\d{10}',
		message: function (value, attribute, validatorOptions, attributes, globalOptions) {
			if (!value) {
				return
			}
			const message = 'can\'t be less than 10 digits'
			return message
		},
	},
}

export const validationMileage = {
	presence: {allowEmpty: false},
	numericality: {
		strict: true,
		onlyInteger: true,
		greaterThanOrEqualTo: 0,
		lessThanOrEqualTo: 1000000,
		notLessThanOrEqualTo: 'must be less then %{count}',
	},
}

export const validationListedPrice = {
	presence: {
		allowEmpty: false
	},
	numericality: {
		strict: true,
		onlyInteger: true,
		greaterThanOrEqualTo: 0
	}
}

export const validationPostLink = {
	presence: {
		allowEmpty: true
	},
	url: true,
}

export const validationPassword = {
	presence: {allowEmpty: false},
	length: {
		minimum: 8,
		message: function (value, attribute, validatorOptions, attributes, globalOptions) {
			if (!value) {
				return
			}
			let message
			if (validatorOptions.minimum && value.length < validatorOptions.minimum) {
				message = 'is too short (minimum is %{count} characters)'.replace('%{count}', validatorOptions.minimum)
			}
			else if (validatorOptions.maximum && value.length > validatorOptions.maximum) {
				message = 'is too long (maximum is %{count} characters)'.replace('%{count}', validatorOptions.maximum)
			}
			return message
		},
	},
}

export const validationConfirmPassword = {
	...validationPassword,
	equality: {
		attribute: "password",
		message: "^does not match",
	},
}