// import { Box } from '@mui/material';
import React, {useEffect} from 'react';
// import carLogo from "../../img/car-logo.svg";
// import ribbon from "../../img/ribbon.svg";
// import carmax from "../../img/carmax.svg";
// import AccordionWithResult from '../../components/accordion/AccordionWithResult';
// import { Button } from '../../components/form/Button';
// import {useNavigate} from 'react-router-dom';
import {Container, useMediaQuery} from '@mui/material';
import TitleComponent from '../../components/title/Title'
import VehicleComponent from "../../components/vehicle/Vehicle";
import OffersComponent from "../../components/offers/Offers";
import {useTheme} from "@mui/material/styles";
import {connect} from "react-redux";
import {initVehicles, Vehicle} from "../../store/actions/vehicles";
import { RootState } from '../../app/store';
import { useNavigate } from 'react-router-dom';
import {OfferHelper} from "../../shared/offer-helper";

class DashboardProps {
	onLoad: any;
	selectedVehicle?: Vehicle;
	vehicles?: Vehicle[];
	isInited?: boolean;
}

const Dashboard = (props: DashboardProps) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const navigate = useNavigate();
	const onLoad = props.onLoad;

	useEffect(() => {
		onLoad((err: any, data: any) => {
			if (err) {
				return ;
			}
			if ((data?.selectedVehicle?.selectedOffer || data?.selectedVehicle?.acceptedOffer) && !OfferHelper.isOfferExpired(data.selectedVehicle.selectedOffer)) {
				navigate('/offer');
			}
		});
	}, [onLoad, navigate]);
	
	return (
		<React.Fragment>
			<TitleComponent/>
			<Container maxWidth="lg" disableGutters={isMobile ? false : true}>
				<VehicleComponent/>
				<OffersComponent/>
			</Container>
		</React.Fragment>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		account: state.account,
		isInited: state.vehicles.isInited,
		vehicles: state.vehicles.list,
		selectedVehicle: state.vehicles.selected,
		// dashboard: state.dashboard
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onLoad: (cb?: any) => dispatch(initVehicles(cb)),
		// onSetSelectedVehicle: (id) => dispatch(actions.setSelectedVehicle(id)),
		// onUpdatePanelTab: (tab, expanded) => dispatch(actions.updatePanelTab(tab, expanded)),
		// onUpdateTabOnly: (tab, expanded) => dispatch(actions.updateTabOnly(tab, expanded))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);