import {Box, Container, Link} from "@mui/material";
import LoginFooter from "../footer/LoginFooter";
import MainLogo from "../logo/MainLogo";
import './auth-layout.css'

class AuthLayoutDto {
	children: any;
}

export default function AuthLayout(props: AuthLayoutDto) {
	return (
		<Container maxWidth='xl' className="login-wrapper">
			<Box className="login-content">
				<Box className="logo-wrapper" sx={{
					display: "flex",
					justifyContent: 'center',
				}}>
					<MainLogo width={'280px'} height={'74px'}/>
				</Box>
				<Box className="login-line"/>
				{props.children}
			</Box>
			<Box className="login-footer-wrapper">
				<LoginFooter/>
			</Box>
		</Container>
	);
}