import {ActionTypes} from './action-types';
import {AppMessage, AppMessageType, setAppStateMessage} from './app';
import {getAccount} from "./account";
import {Auth0Config} from '../../config/auth0';
import axios from '../../axios';
import {parseAuth0Result, TokenStorage, UserStorage, 
    LoginFormStorage, base64Encode, AuthTokenDto, ValueParameter} from '../../shared/auth-utility';
import {mapError} from '../../shared/error-handler';
import { NavigateFunction } from 'react-router-dom';
import { AuthError } from '../reducers/auth';

const webAuth = Auth0Config.configure({
    redirectUri: window.location.origin + '/login',
});

export const authStart = () => {
    return {
        type: ActionTypes.AUTH_START
    };
};

export const authStop = () => {
    return {
        type: ActionTypes.AUTH_STOP
    }
}

export const authSetUserVerification = (mustVerify: boolean) => {
    if (!mustVerify) {
        LoginFormStorage.remove();
    }
    
    return {
        type: ActionTypes.AUTH_SET_USER_VERIFICATION,
        verify: mustVerify
    }
}

export const authSetResetPassword = (values: any) => {
    return {
        type: ActionTypes.AUTH_SET_RESET_PASSWORD,
        resetPassword: values
    }
}

export const authSuccess = (user: any, email?: string, token?: any, ) => {
    LoginFormStorage.remove();
    return {
        type: ActionTypes.AUTH_SUCCESS,
        user,
        email,
        token
    }; 
};

const authSetLoginFormEmail = (email: string) => {
    return {
        type: ActionTypes.AUTH_SET_LOGIN_FORM_EMAIL,
        email
    };
}

export const logoutSuccess = () => {
    return {
        type: ActionTypes.AUTH_LOGOUT_SUCCESS,
        user: null,
    };
};

export const authFail = (error: AuthError) => {
    if (error && error.message) {
        error.message = mapError(error.message);
    }
    console.log('onAuthFail', error);

    return {
        type: ActionTypes.AUTH_FAIL,
        error: { message: error.message, error: JSON.stringify(error) },
    };
};

export const authSetToken = (token?: AuthTokenDto) => {
    return {
        type: ActionTypes.AUTH_SET_TOKEN,
        token: token ? token : null,
    };
};

export const logout = (onRedirect?: any) => {
    return async (dispatch: any, getState: any) => {
        if (!getState().auth.isAuthenticated) {
            return ;
        }

        try {
            await webAuth.logout({
                returnTo: window.location.origin
            });
            dispatch(logoutSuccess());
            
            //onRedirect('/')
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
};

export const checkAuthTimeout = (expirationTime: number) => {
    return (dispatch: any) => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const authError = (error: AuthError) => {
    return (dispatch: any, getState: any) => {
        dispatch(authFail(error));
    }
};

export const authSetTokenTimer = (token: AuthTokenDto | any) => {
    return (dispatch: any, getState: any) => {
        const now = (new Date()).getTime();
        if (token && token.expiresAt) {
            const expiresAt = +token.expiresAt + 2 * 1000 * (+token.expires_in); // todo:
            const date = new Date();
            date.setTime(expiresAt);
            // console.log('now', [now, expiresAt, date]);
            // console.log('token', token);
            setTimeout(() => {
                dispatch(logoutSuccess());
            }, token.expiresAt - now); 
        }
    }
};

export const checkSession = (token: AuthTokenDto, cb?: any) => {
    return (dispatch: any) => {
        dispatch(authStart());

        const user = UserStorage.get();
        if (user && user.email && !TokenStorage.isExpired(token as ValueParameter) && token.id_token) {
            dispatch(authSuccess(user, user.email, token))
            dispatch(getAccount());
            return ;
        }
        
        webAuth.checkSession({
            //audience: window.location.origin,
            scope: token.scope
        }, (err: any, result: any) => {
            if (err) {
                let message: string = typeof err === 'string' ? err : 'Unknown error'; 
                if (!message && err && err.message) {
                    message = err.message;
                } else if (!message && err && err.description) {
                    message = err.description;
                } else if (!message) {
                    message = 'Login error';
                }

                dispatch(authError(new Error(message)));
                return ;
            }
            const user = result.idTokenPayload;
            const updatedToken = {
                ...token,
                access_token: result.accessToken,
                id_token: result.idToken,
                expires_in: result.expiresIn
            }

            dispatch(authSuccess(user, user.email, updatedToken));
            dispatch(getAccount());
        });
    }
};

export const auth = (email: string, password: string, onRedirect: any) => {
    return async (dispatch: any) => {
        dispatch(authStart());
        let redirect;

        let data: any = {};

        try {
            //const user = await Auth.signIn(email, password);
            webAuth.login({
                email: email,
                password: password,
                realm: Auth0Config.connection
            }, (err: any, user: any) => {

                console.log('on login', [err, user]);

                if (err) {
                    console.log('err', err.description);
                    dispatch(authFail(new Error(err.description)));
                    return ;
                }

                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    redirect = '/auth/set-password'
                    const message = new AppMessage('You need to set a new password for the account.');
                    dispatch(setAppStateMessage(message))
                } else {
                    redirect = '/dashboard'
                }

                console.log('on login', user);

                // dispatch(authSuccess(user));
                // dispatch(getAccount())
            });
        } catch (error: any) {

            data.error = error;
            if (error.code === "PasswordResetRequiredException") {
                data.error = null;
                dispatch(setAppStateMessage(
                    new AppMessage('Password reset required for the user. Please check your email address.')
                ));
                redirect = '/auth/reset-password';
            } else {
                redirect = null;
            }

            dispatch(authFail(error));
        }

        onRedirect(redirect)

    };
};

export const register = (data: any, onSuccess: any) => {

    return async (dispatch: any, getState: any) => {
        dispatch(authStart());
        try {
            
            const headers = {
                'Content-Type': 'application/json'
            }
            const res = await axios.post(`auth/signup`, {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                zip: data.zip,
                password: data.password
            }, {headers: headers})

            if (res.data && res.data.error) {
                throw new Error(res.data.error);
            }
            dispatch(authStop());
            onSuccess && onSuccess();
        } catch (error: any) {
            if (String(error).match(/status\s+code\s+403/)) {
                dispatch(authFail(new Error('Login required')));
            }
            dispatch(authFail(error));
        }
    };
};

const verifyUserCode = (data: any, cb: any) => {
    
    return async (dispatch: any, getState: any) => {
        dispatch(authStart());
        try {
            
            const headers = {
                'Content-Type': 'application/json'
            }
            const res = await axios.post(`auth/verify`, {
                code: data.code,
                id: data.id
            }, {headers: headers})

            if (res.data && res.data.error) {
                throw new Error(res.data.error);
            }
            dispatch(authStop());
            cb && cb(null, res.data);
        } catch (error: any) {
            if (String(error).match(/status\s+code\s+403/)) {
                dispatch(authFail(new Error('Login required')));
            }
            dispatch(authFail(error));
            cb && cb(error, null);
        }
    };
};


export const sendEmailConfirmation = (email: string, cb: any) => {
    return async (dispatch: any, getState: any) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        dispatch(authStart());
        try {
            const res = await axios.post(`auth/resend-confirmation`, {
                email: email
            }, {headers: headers})
            const data = res.data;

            if (data && data.error) {
                //dispatch(setAppStateMessage({ type: 'error', message: data.error }));
                dispatch(authFail(new Error(data.error)));
                cb(data.error, null);
                return ;
            }
            
            dispatch(authStop()); 
            if (data && data.message) {
                const message = data.message ? data.message : 'A confirmation has been sent to email';
                dispatch(setAppStateMessage(new AppMessage(message, AppMessageType.Success)));
                cb(null, { message: message });
            }
        } catch(err) {
            dispatch(authStop());
            console.error(err);
            cb('Connection error');
        }
    }
}


export const forgotPassword = (email: string, onRedirect: any) => {
    return async (dispatch: any) => {
        dispatch(authStart());
        // let redirect = null;

        try {
            // await Auth.forgotPassword(
            //     email,
            // )

            // webAuth.changePassword({
            //     client_id: Auth0Config.clientId, 
            //     email: email,
            //     connection: Auth0Config.connection 
            // }, (err, res) => {
            //     console.log('on change password err', err);
            //     console.log('on change password res', res);

            //     const data = {type: 'success', msg: 'A new confirmation code has been sent to your email address.'}
            //     dispatch(setAppStateMessage(data))
            //     dispatch(logoutSuccess());
            //     //redirect = '/auth/reset-password'
            //     redirect = '/'; // to login form
            //     onRedirect(redirect);
            // });

            // webAuth.passwordlessStart({
            //     connection: 'email',
            //     send: 'code',
            //     email: email
            // }, (err, res) => {
            //     console.log('on change password err', err);
            //     console.log('on change password res', res);
            // });

            const res = await axios.post(`auth/reset-password`, {
                email: email
            }, {headers: { 'Content-Type': 'application/json' }})
            const data = res.data;
            if (data.error) {
                console.log('data.error', data);
                throw new Error(data.error);
            }
            
            dispatch(authSetResetPassword({
                email: email
            }));
            onRedirect && onRedirect(null, '/auth/reset-password')

        } catch (error: any) {
            dispatch(authFail(error));
            onRedirect && onRedirect(error, null)
        }

       
    };
};

export const resetPassword = (email: string, code: string, password: string, cb: any) => {
    return async (dispatch: any) => {

        dispatch(authStart());
        //let redirect = null;

        // try {
        //     await Auth.forgotPasswordSubmit(
        //         email,
        //         code,
        //         password,       // the new password
        //     )
        //     redirect = '/'
        //     const data = {type: 'success', msg: 'Password has been changed. Please login with new password'}
        //     dispatch(setAppStateMessage(data))
        //     dispatch(authSuccess());
        // } catch (error) {
        //     dispatch(authFail(error));
        // }
        const webAuth = Auth0Config.configure({
            redirectUri: window.location.origin + '/login?reset-password=result&pwd=' + base64Encode(password)
        }, true);

        try {
            webAuth.passwordlessLogin({
                connection: 'email',
                email: email,
                verificationCode: code
            }, (err: any, resp: any) => {
                if (err) {
                    let message = String(err.description);
                    if (message.indexOf('The verification code has expired') > -1) {
                        message = 'The verification code has expired. Please try to reset password again';
                    }

                    cb ? cb({message: message}) : dispatch(authFail(new Error(message)));
                    return ;
                }

                dispatch(authStop());
                // onRedirect(redirect);
            });
        } catch (error: any) {
            console.log('on reset password error', error);
            cb ? cb(error) : dispatch(authFail(error));
        }

        // onRedirect(redirect)
    };
};

export const setPassword = (user: any, password: string, onRedirect: any) => {
    return async (dispatch: any) => {

        dispatch(authStart());
        let redirect = null;
        try {
            // await Auth.completeNewPassword(
            //     cognitoUser,               // the Cognito User Object
            //     password,       // the new password
            // )
            await webAuth.changePassword({
                client_id: Auth0Config.clientId,
                email: user.email,
                connection: Auth0Config.connection
            });
            dispatch(authSuccess(user));
            redirect = '/'

        } catch (error: any) {
            console.log(error.message)
            if (error.message === 'Invalid session for the user, session is expired.') {
                error.message = 'Your session time has been expired.\n' +
                    'Please reenter your email and temporary password again.'
                redirect = '/'
            }

            dispatch(authFail(error));
        }
        if (redirect) {
            onRedirect(redirect)
        }
    };
};

// export const changePassword = (user: any, newPassword: string, onRedirect: any) => {
//     return async (dispatch: any) => {

//         dispatch(authStart());
//         let redirect = null;
//         try {

//             // await Auth.changePassword(
//             //     cognitoUser,               // the Cognito User Object
//             //     oldPassword,
//             //     newPassword,       // the new password
//             // )
//             await webAuth.changePassword({
//                 client_id: Auth0Config.clientId,
//                 email: user.email,
//                 connection: Auth0Config.connection
//             }, (err: any, res: any) => {
//                 console.log('on change pass err', err);
//                 console.log('on change pass res', res);
//                 if (err) {
//                     dispatch(authFail(err));
//                     return ;
//                 }
//                 dispatch(setAppStateMessage(
//                     new AppMessage('Password has been changed', AppMessageType.Success)
//                 ))

//                 dispatch(authSuccess(user));
//                 onRedirect(null, '/login');
//             });
//             // const data = {type: 'success', msg: 'Password has been changed'}
//             // dispatch(setAppStateMessage(data))
//             //
//             // dispatch(authSuccess());
//             // redirect = '/'
//         } catch (error: any) {
//             if (error.message === 'Incorrect username or password.') {
//                 error.message = 'Incorrect Current Password'
//             }
//             dispatch(authFail(error));
//             onRedirect(error, redirect)
//         }
//     };
// };


export const setAuthRedirectPath = (path: string) => {
    return {
        type: ActionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authInit = () => {
    return {
        type: ActionTypes.AUTH_INIT
    }
};

export const authCheckState = (state: any, navigate: NavigateFunction) => {
    return (dispatch: any) => {
        // todo: replace
        // if (!state.auth.user || (state.auth.user && state.auth.user.signInUserSession && state.auth.user.signInUserSession.accessToken.payload.exp * 1000 <= new Date().getTime())) {
        //     Auth.currentAuthenticatedUser().then((data) => {
        //         dispatch(authSuccess(data));
        //         dispatch(getAccount())
        //     }).catch(e => {
        //         console.log(e)
        //     })
        // }

        //https://localhost:3000/#access_token=s_BWzqcbSrDBuPz45_Efih9KJ5KthOux&scope=openid%20profile%20email
        //&expires_in=7200&token_type=Bearer&state=3c4rLaGM~PcjSDZ~t4At4z0QVHbq3ohR&id_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZLcVhiTExSLTJGRDJoOENwbURlbSJ9.eyJuaWNrbmFtZSI6InZpdGFseS5zdHJpemhlbm9rIiwibmFtZSI6InZpdGFseS5zdHJpemhlbm9rQHRlcW5pa3NvZnQuY29tIiwicGljdHVyZSI6Imh0dHBzOi8vcy5ncmF2YXRhci5jb20vYXZhdGFyLzU5MDEzZTNmZDlhYmUwNjFiYmQ5YmIzYjJmMzJlOGUzP3M9NDgwJnI9cGcmZD1odHRwcyUzQSUyRiUyRmNkbi5hdXRoMC5jb20lMkZhdmF0YXJzJTJGdmkucG5nIiwidXBkYXRlZF9hdCI6IjIwMjAtMDgtMDJUMTg6NTM6MTIuMjQ0WiIsImVtYWlsIjoidml0YWx5LnN0cml6aGVub2tAdGVxbmlrc29mdC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9jYXJ3aXNlci51cy5hdXRoMC5jb20vIiwic3ViIjoiZW1haWx8NWYyNmY0Y2FiZTAzZDI0ZGM5MTFiNGU1IiwiYXVkIjoiOTZJSERNYUVhdHM4T1d3RzZGQk10QWhtMDBWT3ByMVUiLCJpYXQiOjE1OTYzOTQzOTIsImV4cCI6MTU5NjQzMDM5MiwiYXRfaGFzaCI6IlZZS0RfOTlvelJCRkYwS2Y2THpBLUEiLCJub25jZSI6IjRFVHRUY3d6NkpCdmFndWpjekJlb1p1clJ2cDJsNjRCIn0.SvVSInUZiFPOXsazKaewqulCEWsSArP2EWq06Q0o4aduFVonZX1Jv85K_M5KtvvAxv6ry9IhLA56gsTPSO1omuPxgZSmqLKzpgB5USJKXPHOS_jDISTKlOWBVnNj81wOMGtUSVr71ipFqqkoL7b42AqIhC8U2oucbvAlEJb8VrV0kGNs_H_xzYV48eu_O6eXi0Ccb2QC7qynPEdPi_v-e4RxRlHUGF1D-uFWiWLCBz7cUj7ahDFzCW-ssWQFut1dIMcuGgxRqgAkEDHEw8o5hyM6Ve9fV8wJBBDpM05XW-nE4Uj3kfBZJtJ8Vd88AOh8XHZ6JLyBJPEy0anR0M6_IQ

        const isParametersLaunched = parseAuth0Result(dispatch, state, navigate);
        if (isParametersLaunched) {
            return ;
        }

        const isVerificationCodeLaunched = parseVerificationCodeResult(dispatch, state, navigate);
        if (isVerificationCodeLaunched) {
            return ;
        }

        if (state.auth.isAuthenticated || state.auth.status === 'authenticating') {
            return ;
        }
        if (state.auth.token) {
            dispatch(checkSession(state.auth.token));
        }
    };
};

export const authRemoveTemporaryPassword = () => {
    return (dispatch: any, getState: any) => {

        const state = getState();
        if (!state.auth.hasTemporaryPassword) {
            return ;
        }
        const setHasTmp = (obj: any) => {
            obj = obj || {};
            for(let prop in obj) {
                if (prop.indexOf('/has_tmp_pass') === -1) {
                    continue;
                }
                obj[prop] = false;
            }
        }

        const user = UserStorage.get();
        setHasTmp(user);
        UserStorage.save(user);
        
        const rxToken = {
            ...state.auth.token,
            has_tmp_pass: false
        }
        const rxUser = {
            ...state.auth.user 
        }
        setHasTmp(rxUser);
        dispatch(authSuccess(rxUser, rxUser.email, rxToken));
    }
}

const parseVerificationCodeResult = (dispatch: any, state: any, navigate: NavigateFunction) => {
    const search = String(window.location.search).replace(/^\?/, '');
    if (!(search && search.indexOf('id=') > -1 && search.indexOf('vc=') > -1)) {
        return false;
    }
    const query = new URLSearchParams(String(search));
    const id = query.get('id');
    const code = query.get('vc');
    navigate('/');

    dispatch(verifyUserCode({ id, code }, (err: any, data: any) => {
        if (err) {
            return ;
        }

        const message = data && data.message ? data.message : 'User verified successfully';
        dispatch(setAppStateMessage(new AppMessage(message, AppMessageType.Success)));

        console.log('data', data);
        const email = data && data.seller ? data.seller.email : null;
        email && dispatch(authSetLoginFormEmail(email));
    }));

    return true;        
}