import React, { useEffect } from 'react';
import './RefreshOffersLoading.css';
import LoaderComponent from './Loader';

const RefreshOffersLoading = () => {

    useEffect(() => {
        document.getElementsByClassName("refresh-offers-loading-container")[0]?.scrollIntoView();
    }, []);

    return (
        <div className='refresh-offers-loading-container'>
            <div className='title'>Refreshing Your Offers...</div>
			<LoaderComponent/>
        </div>
    )
}

export default RefreshOffersLoading