import React, {useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Box, Skeleton, useMediaQuery} from "@mui/material";
import carBadge from "../../img/best-offer-badge.png";
import {VehicleImage} from "../../store/actions/vehicles";

type VehicleImageBlockDto = {
	image?: VehicleImage | null | undefined;
}

const VehicleImageBlock = (props: VehicleImageBlockDto) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	let srcImage = isMobile ? props.image?.mobile : props.image?.desktop
	srcImage = srcImage?.replace(/http/i, 'https')

	if (!srcImage) {
		return null
	}

	let imgSize
	let badgeSize
	if (isMobile) {
		imgSize = {
			w: 320,
			h: 240
		}
		badgeSize = {
			w: '50px',
			h: '66px',
		}
	} else {
		imgSize = {
			w: 480,
			h: 360
		}
		badgeSize = {
			w: '90px',
			h: '116px',
		}
	}

	return (
		<Box sx={{
			display: {xs: 'flex'},
			alignItems: {xs: 'flex-start'},
			justifyContent: 'center',
		}}>
			<Box sx={{
				position: 'relative',
			}}>
				{!isLoaded && <Skeleton animation={'wave'} variant={'rectangular'} width={imgSize.w} height={imgSize.h} sx={{borderRadius: '6px'}}/>}
				<Box component={'img'} src={srcImage} onLoad={() => setIsLoaded(true)} sx={{height: `${imgSize.h}px`, display: isLoaded ? 'block' : 'none'}}/>
				{isLoaded && <Box component={'img'} src={carBadge} sx={{
					position: 'absolute',
					left: '0',
					top: {xs: '0', sm: '-50px'},
					width: badgeSize.w,
					height: badgeSize.h
				}}/>}
			</Box>
		</Box>
	)
}

export default VehicleImageBlock