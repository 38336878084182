import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import {connect} from "react-redux";
import { RootState } from '../../app/store';
import { setAppStateMessage } from '../../store/actions/app';
import './SnackBar.css';

const CustomizedSnackbars = (props: any) => {
    const handleClose = (event: any, reason?: any) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onSetMessage(null);
    };


    return (props.appStateMessage &&

        <div className={'snackbar-root'}>
            <Snackbar open={!!props.appStateMessage} 
                autoHideDuration={8000} onClose={handleClose} disableWindowBlurListener={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center'  }}>
                <Alert severity={props.appStateMessage.type} onClose={handleClose}>
                    {props.appStateMessage ? props.appStateMessage.msg : ''}
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        appStateMessage: state.app.stateMessage,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSetMessage: (message: any) => dispatch(setAppStateMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedSnackbars);