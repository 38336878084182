import { ActionTypes } from "./action-types";

export enum AppMessageType {
    Info = 'info',
    Success = 'success',
    Error = 'error',
}

export class AppMessage {
    type!: AppMessageType;
    msg!: string;

    constructor(msg: string, type?: AppMessageType) {
        this.msg = msg;
        this.type = type || AppMessageType.Info;
    }

    static Error(msg: string) {
        return new AppMessage(msg, AppMessageType.Error);
    }

    static Success(msg: string) {
        return new AppMessage(msg, AppMessageType.Success);
    }

    static Info(msg: string) {
        return new AppMessage(msg, AppMessageType.Info);
    }
}

export const clearAppStateMessage = () => {
    return {
        type: ActionTypes.SET_APP_STATE_MESSAGE,
        message: null,
    }
}

export const setAppStateMessage = (message: AppMessage) => {
    return {
        type: ActionTypes.SET_APP_STATE_MESSAGE,
        message
    };
};

export const setAppLoading = (loading: boolean) => {
    return {
        type: ActionTypes.SET_APP_LOADING,
        loading: !!loading,
    }
}