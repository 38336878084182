import './DrawerFooter.css'

import {Box} from '@mui/material'
import React from 'react'

export default function DrawerFooter() {
	return (
		<React.Fragment>
			
			<Box className={'footerPanel'} display="flex" alignItems="center" justifyContent="center">
        <img src={'https://images.carwiser.com/carwiser/Carwiser_PoweredBy_Color.svg'}/>
			</Box>
		</React.Fragment>
	)
}
