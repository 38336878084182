import { Box } from "@mui/material";
import React from "react";
import { NumberFormatter } from "../../shared/formatter";
import { OfferHelper } from "../../shared/offer-helper";
import { Offer } from "../../store/actions/offers";

class OfferAmountDetailDto {
    offer!: Offer;
}

export default function OfferAmountDetail(props: OfferAmountDetailDto) {
    if (!props.offer) {
        return null;
    }
    let amount;
    if (OfferHelper.isOfferInProgress(props.offer)) {
        amount = (<Box sx={{fontSize: '36px',
        textTransform: 'uppercase',
        lineHeight: '40px',
        color: '#2A2F31'}}>In-progress</Box>);
    } else {
        amount = (
            <React.Fragment>
                <Box component={'span'} sx={{fontSize: {xs: '36px', sm: '72px'}}}>$</Box>
			    <Box component={'span'}
						sx={{fontSize: {xs: '72px', sm: '128px'}}}>{NumberFormatter.format(String(props.offer.amount))}</Box>
            </React.Fragment>
        );
    }

    return (
        <Box
            component="strong"
            sx={{
			    display: 'flex',
			    alignItems: 'flex-start',
				justifyContent: {xs: 'center', lg: 'flex-start'},
			    lineHeight: 1,
			    fontWeight: 700
		    }}>
            {amount}
		</Box>
    );
}