import {AffiliateProgram, PrimaryNamesType} from "./matchingClientsHelper";

interface ProviderLogo {
    provider: any;
    isGray?: boolean;
}

export function getProviderLogo({provider, isGray = false}: ProviderLogo): string | undefined {
    if (!provider || provider.provider === 'default' || !provider.provider) {
        return undefined
    }

    let providerName = provider.provider

    if (providerName === PrimaryNamesType.Echopark && provider.secondaryProvider) {
        providerName = provider.secondaryProvider
    }

    if (providerName === AffiliateProgram.carvana.name) {
        providerName = PrimaryNamesType.Carvana
    }

    let logo = `${process.env.REACT_APP_IMAGES_STORE}/providers/logos/${providerName}.svg`
    // let logo = `${process.env.REACT_APP_IMAGES_STORE}/providers/${isGray ? 'grayscale' : 'color'}/${provider}.svg`
    return logo
}