import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import './SelectField.css';
import checkImg from "../../img/check-circle.svg";
import selectArrow from "../../img/select-arrow.svg";
import errorImg from "../../img/error-circle.svg";
import { Box, FormHelperText } from "@mui/material";

export type DropdownItemType = {
	key: string
	value: string
	default?: boolean
}

type SelectFieldProps = {
	label?: string
	value?: string
  checked?: boolean
  error?: boolean
	items?: Array<DropdownItemType>
	onChange: any
	disabled?: boolean
  helperText?: string
}

export function SelectField(props: SelectFieldProps) {

  const {value, label, onChange, checked, error, helperText, items, disabled} = props;

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <Box className="input-wrapper">
      <Box className={'form-select' + (error ? " has-error" : "")}>
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            value={value as string}
            label={label}
            onChange={handleChange}
            disabled={disabled}
            MenuProps={{
              style: {
                maxHeight: 300,
              }
            }}
          >
            {items && items.map((item) => {
              return <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            })}
          </Select>
          <img className="arrow" src={selectArrow} alt="arrow" />
          {checked && <img className="errorImg" src={checkImg} alt="check img" />}
          {error && <img className="errorImg" src={errorImg} alt="error img" />}
          {error && helperText && <FormHelperText>{helperText}</FormHelperText>} 
        </FormControl>   
      </Box>
    </Box>
)}