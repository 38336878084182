import { CustomTextFieldProps, FormInputProps, 
    FormTextField, FormTextFieldProps, TextField } from './TextField';
import InputMask from 'react-input-mask';
import { FormMaskedField } from './TextField.dto';
import { inputChangedHandler } from '../../shared/utility';
// import { IMaskInput } from 'react-imask';


class PhoneTextFieldProps {
    disabled?: boolean;
    onChange?: any;
    value?: string;
    mask?: any;
    inputMaskComponent?: any;
}

type InputComponentDto = PhoneTextFieldProps & CustomTextFieldProps;

export function PhoneTextField(props: InputComponentDto) {
    let {disabled, onChange, value, mask, 
        inputMaskComponent, ...other} = props;
    // return (
    //   <IMaskInput
    //     {...other}
    //     mask={mask}
    //     // definitions={{
    //     //   '#': /[1-9]/,
    //     // }}
    //     inputRef={ref}
    //     onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    //     overwrite
    //   />
    // );
    if (!inputMaskComponent) {
        inputMaskComponent = (inputProps: any) => (
            <TextField {...inputProps} {...other} disabled={disabled}/>
        )
    }
    return (
        <InputMask mask={mask} disabled={disabled} value={value} onChange={onChange}>
        { // @ts-ignore
         inputMaskComponent
        }
        </InputMask>
    );
}

export type FormPhoneTextFieldProps = FormInputProps & FormTextFieldProps;

export function FormPhoneTextField(props: FormPhoneTextFieldProps) {
    const {disabled, ...other} = props;
    const field = other.field as FormMaskedField;
    if (typeof other.onChange === 'undefined') {
        other.onChange = ((event: any) => inputChangedHandler(props.formState, props.setFormState, 
                    event.target.value, field?.key ?? '', true));
    } 
    return (<PhoneTextField
        {...other}
        disabled={disabled}
        mask={field.mask}
        value={field.value}
        onChange={other.onChange}
        inputMaskComponent={(inputProps: any) => (
            <FormTextField {...inputProps} {...other} field={field} disabled={disabled}
                onChange={other.onChange} />
        )}
    />)
}