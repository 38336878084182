import {updateObject} from "../../shared/utility";
import { ActionTypes } from "../actions/action-types";


export class AccountState {
    id?: string;
    status?: number;
    fullName?: string;
    avatar?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
}

const initialState = new AccountState();

const setAccount = (state: AccountState, action: any) => {
    return updateObject(state, {...action.account})
}


const reducer = (state:AccountState = initialState, action: any): AccountState => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_SET:
            return setAccount(state, action);
        default:
            return state;
    }
};

export default reducer;