import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/action-types";
import { Offer } from "../actions/offers";

export class OffersState {
    loading: boolean = false;
    selected?: Offer;
}

const initialState: OffersState = new OffersState();

const setSelectedOffer = (state: OffersState, action: { offer: Offer }) => {
    const updatedState: OffersState = JSON.parse(JSON.stringify(state));
    updatedState.selected = action.offer;
    return updatedState;
}

const setStateLoading = (state: any, action: any) => {
    return updateObject(state, {
        loading: !!action.loading
    });
}

const reducer = (state: OffersState = initialState, action: any): OffersState => {
    switch (action.type) {
        case ActionTypes.OFFERS_SET_SELECTED:
            return setSelectedOffer(state, action);
        case ActionTypes.OFFERS_SET_LOADING:
            return setStateLoading(state, action);
        default:
            return state;
    }
};

export default reducer;