import {updateObject, cloneObject} from "../../shared/utility";
import { ActionTypes } from "../actions/action-types";

const getDarkModeFromStorage = () => {
    if (localStorage.getItem('darkMode')) {
        return localStorage.getItem('darkMode') === 'true'
    } else {
        return true;
    }
}

class AppState {
    stateMessage: any = null;
    darkMode = getDarkModeFromStorage();
    selectedVehicleIndex: any = null;
    selectedVehicle: any = null;
    loading: boolean = false;
}

const initialState = new AppState();

const setStateLoading = (state: any, action: any) => {
    return updateObject(state, {
        loading: !!action.loading
    });
}

const setStateMessage = (state: any, action: any) => {
    return updateObject(state, {stateMessage: action.message})
}

const setDarkMode = (state: any, action: any) => {
    return updateObject(state, {darkMode: action.darkMode})
}

const setData = (state: any, action: any) => {
    const updatedState = cloneObject(action.data)
    return updateObject(state, updatedState);
}

const reducer = (state: any, action: any): AppState => {
    state = state || initialState;
    switch (action.type) {
        case ActionTypes.SET_APP_STATE_MESSAGE:
            return setStateMessage(state, action);
        case ActionTypes.SET_APP_DARK_MODE:
            return setDarkMode(state, action);
        case ActionTypes.SET_APP_LOADING:
            return setStateLoading(state, action);
        case ActionTypes.SET_APP_DATA:
            return setData(state, action);
        default:
            return state;
    }
};

export default reducer;