import React, {useState, useEffect} from 'react';
import {getFormData, inputChangedHandler, prepareFormData, validateForm} from "../../shared/utility";
import {connect} from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { RootState } from '../../app/store';
import TermsModal from '../../components/modals/TermsModal';
import { FormTextField } from '../../components/form/TextField';
import { Button } from '../../components/form/Button';
import { changePassword } from '../../store/actions/account';
import { authRemoveTemporaryPassword } from '../../store/actions';

let formData = {
    controls: {
        password: {
            key: '',
            helperText: '',
            label: 'New Password',
            value: '',
            validation: {
                presence: {allowEmpty: false},
                length: {minimum: 8, allowEmpty: false}
            },
            valid: false,
            touched: false,
            showPassword: false,
        },
        repassword: {
            key: '',
            helperText: '',
            label: 'Retype New Password',
            value: '',
            validation: {
                presence: {allowEmpty: false}, 
                length: {minimum: 8, allowEmpty: false},
                equality: { attribute: "password", message: 'Passwords do not match' }
            },
            valid: false,
            touched: false,
            showPassword: false,
        },
        terms: {
            key: '',
            helperText: '',
            value: true,
            validation: {
                presence: {allowEmpty: false}
            },
            valid: true,
            label: 'I opt-in to Carwiser terms and conditions.',
            touched: false,
        }
    },

    loading: false,
    valid: false,
    touched: false,
    generalError: null

}

const SetPassword = (props: any) => {
    // todo: !!!
    const classes = {
        form: undefined,
        buttonProgress: undefined,
        wrapper: undefined,
        termsModal: undefined,
        checkbox: undefined,
        formControl: undefined,
    }; // useStyles();
    formData = prepareFormData(formData)
    const [formState, setFormState] = useState(formData);
    const [termsModal, setTermsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.hasTemporaryPassword) {
            navigate('/dashboard');
        }
    }, [props.hasTemporaryPassword, navigate]);

    const submitHandler = async (event: any) => {
        event.preventDefault();
        formData = prepareFormData(formData);
        if (validateForm(formState, setFormState)) {
            setLoading(true);
            const data = getFormData(formState)
            props.onSetPassword(props.user, data.password, (err: any, url: any) => {
                setLoading(false);
                if (err) {
                    return ;
                }
                if (props.hasTemporaryPassword) {
                    props.removeTemporaryPassword();
                }
                navigate(url);
            })
        }
    }

    const passwordField = formState.controls.password;
    const repasswordField = formState.controls.repassword;
    const termsField = formState.controls.terms;

    return (
        <React.Fragment>
            <form className={classes.form} noValidate onSubmit={submitHandler}>
                <div className='login-input-wrapper'>
                    <FormTextField
                        field={passwordField}
                        formState={formState}
                        setFormState={setFormState}
                        type={passwordField.showPassword ? 'input' : 'password'}
                    />

                </div>
                <div className='login-input-wrapper'>
                    <FormTextField
                        field={repasswordField}
                        formState={formState}
                        setFormState={setFormState}
                        type={repasswordField.showPassword ? 'input' : 'password'}
                    />

                </div>

                {props.error &&
                <div>
                    <Alert severity="error">{props.error.message ?? ''}</Alert>
                </div>
                }


                <div className='login-button-wrapper'>
                    <Button type={"submit"} loading={loading} className="LoginButton"> Set Password</Button>
                </div>

                <div className='login-button-wrapper'>
                    <div style={{textAlign: 'left', display: 'none'}}>
                        <FormControl required error={termsField.helperText ? true : false} component="fieldset"
                                        className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.checkbox}
                                    control={<Checkbox
                                        checked={termsField.value}
                                        onChange={() => inputChangedHandler(formState, setFormState, termsField.value ? '' : true, termsField.key)}
                                        color='default'
                                    />}
                                    label={<span onClick={(e) => {
                                        e.preventDefault()
                                        setTermsModal(true)
                                    }} style={{fontSize: "12px"}}>I opt-in to Carwiser <u>terms and conditions</u>.</span>}
                                    // helperText={termsField.helperText}
                                    // error={!termsField.valid && termsField.touched ? true : undefined}
                                />
                            </FormGroup>
                            <FormHelperText>{(termsField.helperText) ? 'You must accept the terms and conditions to continue' : ''}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
            </form>

            <Box className={classes.termsModal}>
                
            </Box>
            <TermsModal open={termsModal} isLoginPage={true}
                            handleClose={() => {
                                setTermsModal(false)
                            }}/>
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        hasTemporaryPassword: state.auth.hasTemporaryPassword,
        error: state.auth.error,
        user: (state.auth.user) ? state.auth.user : null
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeTemporaryPassword: () => dispatch(authRemoveTemporaryPassword()),
        onSetPassword: (user: string, password: string, cb: any) => dispatch(changePassword(user, password, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
