export enum ActionTypes {
    AUTH_INIT = 'AUTH_INIT',
    AUTH_START = 'AUTH_START',
    AUTH_STOP = 'AUTH_STOP',
    AUTH_SET_RESET_PASSWORD = 'AUTH_SET_RESET_PASSWORD',
    AUTH_SUCCESS = 'AUTH_SUCCESS',
    AUTH_SET_TOKEN = 'AUTH_SET_TOKEN',
    AUTH_FAIL = 'AUTH_FAIL',
    AUTH_LOGOUT = 'AUTH_LOGOUT',
    AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS',
    AUTH_SET_USER_VERIFICATION = 'AUTH_SET_USER_VERIFICATION',
    AUTH_SET_LOGIN_FORM_EMAIL = 'AUTH_SET_LOGIN_FORM_EMAIL',
    SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH',

    ACCOUNT_SET = 'ACCOUNT_SET',
    ACCOUNT_CREATE = 'ACCOUNT_CREATE',

    SET_APP_STATE_MESSAGE = 'SET_APP_STATE_MESSAGE',
    SET_APP_DARK_MODE = 'SET_APP_DARK_MODE',
    SET_APP_LOADING = 'SET_APP_LOADING',
    SET_APP_DATA = 'SET_APP_DATA',

    VEHICLES_ADD_VEHICLE = 'VEHICLES_ADD_VEHICLE',
    VEHICLES_SAVE_VEHICLE = 'VEHICLES_SAVE_VEHICLE',
    VEHICLES_LOAD_VEHICLE = 'VEHICLES_LOAD_VEHICLE',
    VEHICLES_SET_SELECTED = 'VEHICLES_SET_SELECTED',
    VEHICLES_SET_INITED = 'VEHICLES_SET_INITED',

    OFFERS_SET_SELECTED = 'OFFERS_SET_SELECTED',
    OFFERS_SET_LOADING = 'OFFERS_SET_LOADING',
    REFRESH_OFFERS_SET_STEP = 'REFRESH_OFFERS_SET_STEP',
    REFRESH_OFFERS_SET_LOADING = 'REFRESH_OFFERS_SET_LOADING',

    SET_CONTACT_AGENT_DATA = 'SET_CONTACT_AGENT_DATA',
}