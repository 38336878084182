import {Box, Typography} from "@mui/material";
import React from "react";

class VehicleDetailItemDto {
	label?: any;
	value?: any;
}

function VehicleDetailItem(props: VehicleDetailItemDto) {
	return (
		<Box>
			<Typography component={'span'} sx={{
				fontSize: {xs: '14px', sm: '18px'},
				lineHeight: {xs: '20px', sm: '28px'},
				fontWeight: {xs: 500, sm: 600},
				marginRight: 1,
				textTransform: 'uppercase',
			}}>
				{props.label}:
			</Typography>
			<Typography component={'span'} sx={{
				fontSize: {xs: '14px', sm: '18px'},
				lineHeight: {xs: '20px', sm: '28px'},
				fontWeight: {xs: 400, sm: 400},
				marginRight: 1,
				textTransform: 'uppercase',
			}}>
				{props.value}
			</Typography>
		</Box>
	)
}

export default VehicleDetailItem