import { combineReducers } from "@reduxjs/toolkit";
import appReducer from './app';
import authReducer from './auth';
import accountReducer from './account';
import vehiclesReducer from './vehicles';
import offersReducer from './offers';
import contactAgent from "./contactAgent";
import refreshOffersReducer from './refreshOffers'

export const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    account: accountReducer,
    vehicles: vehiclesReducer,
    offers: offersReducer,
    refreshOffers: refreshOffersReducer,
    counter: () => ({ value: 0 }),
    // auth: authReducer,
    // dashboard: dashboardReducer,
    // account: accountReducer,
    // newVehicle: newVehicleReducer,
    // vehicles: vehiclesReducer,
    // blackbook: blackbookReducer,
    // messages: messageReducer,
    // qualifyVehicle: QualifyVehicleReducer,
    contactAgent: contactAgent,
  });