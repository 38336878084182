import {WebAuth} from "auth0-js";

const authOpts = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    appOrigin: process.env.REACT_APP_AUTH0_APP_ORIGIN,
    apiOrigin: process.env.REACT_APP_AUTH0_API_ORIGIN,
    scope: "read:current_user update:current_user_metadata",
    connection: "Username-Password-Authentication",

    testUser: {
        firstName:  'VS',
        lastName: 'LN',
        zip: '12345',
        email: 'vitaly.strizhenok@teqniksoft.com',
        password: 'qwertY123'
    }
}

let webAuth: any = null;

export const Auth0Config = {
    ...authOpts,
    configure: (userOptions?: any, createNewInstance = false) => {
        if (webAuth && !createNewInstance) {
            return webAuth;
        }
        userOptions || (userOptions = {});
        const opts = {
            domain: authOpts.domain,
            clientID: authOpts.clientId,
            redirectUri: window.location.origin,
            connection: authOpts.connection,
            responseType: 'token id_token',
            ...userOptions
        };
        webAuth = new WebAuth(opts);

        return webAuth;
    }
}