import React from "react";
import { TextFieldProps } from '@mui/material/TextField';
import { Box, TextField as MuiTextField } from '@mui/material';
import './TextField.css';
import errorImg from "../../img/error-circle.svg";
import checkImg from "../../img/check-circle.svg";
import { FormField, FormMaskedField } from "./TextField.dto";
import { inputChangedHandler } from "../../shared/utility";

export class InputProps {
    label?: any;
    error?: any;
    errorText?: string;
    checked?: boolean = false;
    disabled?: boolean = false;
    value?: any;
    hintText?: string;
    bottomHelperText?: string;
}

export type CustomTextFieldProps = TextFieldProps & InputProps;

export function TextField(props: CustomTextFieldProps) {
  let {label, error, errorText, checked, disabled, helperText, bottomHelperText, ...textFieldProps} = props;
  helperText =  errorText ? errorText: helperText

  return (
    <div className="input-wrapper">
      <MuiTextField
        {...textFieldProps}
        placeholder={label}
        error={error}
        label={label}
        variant="filled"
        helperText={helperText}
        disabled={disabled}
        sx={
            (error || checked)
                ? {
                    '& .MuiFilledInput-input': {
                        paddingRight: '55px',
                    }
                }
                : {}
        }
      />
      {bottomHelperText && <Box className="bottom-helper-text">{bottomHelperText}</Box>}
      {error && <img className="errorImg" src={errorImg} alt="error img" />}
      {checked && <img className="errorImg" src={checkImg} alt="check img" />}
    </div>
)}


export class FormInputProps {
  field!: FormField | FormMaskedField;
  value?: string;
  onChange?: any;
  formState!: any;
  setFormState!: any;
  bottomHelperText?: string;
}

export type FormTextFieldProps = TextFieldProps & FormInputProps;

export function FormTextField(props: FormTextFieldProps) {
  let {field, onChange, formState, setFormState, bottomHelperText, value, ...otherProps} = props;
  let label, checked, errorText, error;
  const hasValue = field.value !== '';
  if (field) {
    typeof field.label !== 'undefined' && (label = field.label);
    if (typeof checked === 'undefined') {
      checked = field.valid && (hasValue || field.touched);
    }
    if (typeof onChange === 'undefined' && typeof formState !== 'undefined' && setFormState) {
      onChange = ((event: any) => inputChangedHandler(formState, setFormState, event.target.value, field?.key ?? '', true));
    }
    if (typeof errorText === 'undefined' && typeof field.helperText !== 'undefined') {
      errorText = field.helperText;
    }
    if (typeof error === 'undefined' && typeof field.valid !== 'undefined' && typeof field.touched !== 'undefined') {
      error = !field.valid && (hasValue || field.touched);
    }
  }

  return (<TextField
    {...otherProps}
    key={field.key}
    checked={checked}
    label={label}
    errorText={errorText}
    error={error}
    onChange={onChange}
    name={field?.key}
    value={value || field?.value}
    bottomHelperText={bottomHelperText}
  />);
}
