import React, {useState} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {getFormData, inputChangedHandler, prepareFormData, validateForm} from "../../shared/utility";
import { ZipFormatter } from '../../shared/formatter';
import { useNavigate } from 'react-router-dom';
import { Box, Container, useMediaQuery } from '@mui/material';
import { FormTextField } from '../../components/form/TextField';
import { Button } from '../../components/form/Button';
import { PhoneTextField } from "../../components/form/PhoneTextField";
import { RootState } from '../../app/store';
import {useTheme} from "@mui/material/styles";
import './MyAccount.css';
import { FormData, FormMaskedField } from '../../components/form/TextField.dto';
import {validationEmail, validationName, validationPhone, validationLastName} from "../../shared/validationRules";
import { setInited } from '../../store/actions/vehicles';

let formData: FormData = {
    controls: {
        avatar: {
            key: '',
            value: '',
            valid: true,
        },
        avatarBase64: {
            key: '',
            value: '',
            valid: true,
        },
        firstName: {
            key: '',
            label: 'First name'.toUpperCase(),
            value: '',
            validation: validationName,
            valid: false,
            touched: false,
        },
        lastName: {
            key: '',
            label: 'Last name'.toUpperCase(),
            value: '',
            validation: validationLastName,
            valid: false,
            touched: false,
        },
        email: {
            key: '',
            label: 'Email Address'.toUpperCase(),
            value: '',
            validation: validationEmail,
            valid: false,
            touched: false,
        },
        phone: {
            key: '',
            label: 'Phone number'.toUpperCase(),
            value: '',
            mask: '(999) 999-9999',
            validation: validationPhone,
            valid: false,
            touched: false,
        } as FormMaskedField,
        zip: {
            key: '',
            label: 'Zip Code'.toUpperCase(),
            value: '',
            validation: {length: {minimum: 1, maximum: 5, allowEmpty: false}},
            inputFilter: '[^0-9]',
            valid: true,
            touched: false,
        } as FormMaskedField,
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

class MyAccountProps {
    account?: any;
    clearAppMessage?: any;
    onSaveAccount?: any;
    error?: any;
    needToReloadVehicles?: any;
}

const MyAccount = (props: MyAccountProps) => {

    const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    formData = prepareFormData(formData, props.account);
    formData.controls.phone.value = formData.controls.phone.value.replace('+1', '')

    const [formState, setFormState] = useState(formData);
    const [loading, setLoading] = useState(false);

    const firstNameField = formState.controls.firstName;
    const lastNameField = formState.controls.lastName;
    const emailField = formState.controls.email;
    const phoneField = formState.controls.phone as FormMaskedField;
    const zipField = formState.controls.zip as FormMaskedField;
    const submitHandler = async (event: any) => {
        event.preventDefault();
        props.clearAppMessage();
        if (validateForm(formState, setFormState)) {
            zipField.value = ZipFormatter.format(zipField.value);
            setFormState(formState);
            const data = getFormData(formState)
            data.phone = '+1' + data.phone
            setLoading(true);
            props.onSaveAccount(data, (err: any, params: any) => {
                setLoading(false);
                props.needToReloadVehicles()
                if (err) {
                    return ;
                }
                navigate('/dashboard');
            });
        }
    }

    return (
        <Container className={'cntx-container'} maxWidth="lg" disableGutters={isMobile ? false : true}>
            <Box>
                    <h1 className={'title'}>Account Profile</h1>
                    <h3 className={'title'}>Update your account profile information</h3>
                    <form noValidate onSubmit={submitHandler}>
                        {/* <div className={classes.avatarWrapper}>
                            <Box display="flex" p={1}>
                                <Box>
                                    <Avatar className={classes.avatar} ref={avatar}
                                            src={avatarBase64Field.value ? avatarBase64Field.value : avatarField.value}>{(props.account && props.account.firstName) ? capitalize(props.account.firstName).charAt(0) : "D"}</Avatar>
                                </Box>
                                <Box className={classes.imageUploader}><ImageUploader formState={formState}
                                                                                    setFormState={setFormState}
                                                                                    datakey={avatarBase64Field.key}/></Box>
                            </Box>


                        </div> */}
                        <Box className={'fields'}>
                            <div className='row'>
                                <FormTextField
                                    field={firstNameField}
                                    formState={formState}
                                    setFormState={setFormState}
                                    type='input'
                                />
                            </div>
                            <div className='row'>
                                <FormTextField
                                    field={lastNameField}
                                    formState={formState}
                                    setFormState={setFormState}
                                    type='input'
                                />
                            </div>
                            <div className='row'>
                                <FormTextField
                                    field={emailField}
                                    formState={formState}
                                    setFormState={setFormState}
                                />

                            </div>
                            <div className='row'>
                                <PhoneTextField
                                    // field={phoneField}
                                    // formState={formState}
                                    // setFormState={setFormState}

                                    name={phoneField.key}
                                    mask={phoneField.mask}
                                    value={phoneField.value}
                                    type='input'
                                    label={phoneField.label}
                                    placeholder={phoneField.label}
                                    checked={phoneField.valid && (phoneField.value || phoneField.touched)}
                                    onChange={(event: any) => inputChangedHandler(formState, setFormState, event.target.value, phoneField.key ?? '', true)}
                                    helperText={phoneField.helperText}
                                    errorText={phoneField.helperText}
                                    error={!phoneField.valid && (phoneField.value || phoneField.touched)}

                                />

                            </div>
                            <div className='row'>
                                <FormTextField
                                    field={zipField}
                                    formState={formState}
                                    setFormState={setFormState}
                                />
                            </div>
                        </Box>

                        <Box className={'buttons'}>
                            <Button className={'first'} variant={'outlined'} onClick={() => {
                                    navigate('/dashboard')
                                }}
                                > Cancel</Button>

                            <Button type={"button"} loading={loading}
                                        onClick={submitHandler}
                                > Save</Button>
                        </Box>
                    </form>
                {/* </Box> */}
            </Box>
        </Container>

    );
}

const mapStateToProps = (state: RootState) => {
    return {
        account: state.account
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSaveAccount: (account: any, cb: any) => dispatch(actions.setAccount(account, cb)),
        clearAppMessage: () => dispatch(actions.clearAppStateMessage()),
        needToReloadVehicles: () => dispatch(setInited(false))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);