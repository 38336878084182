import React, {useEffect, useRef, useState} from 'react'
import { connect } from 'react-redux'
import { Box, Button } from '@mui/material'
import CustomDialog from '../modals/CustomDialog'
import {ContactAgentState} from "../../store/reducers/contactAgent";

type NotificationDto = {
	app: any;
	contactAgent: ContactAgentState
}
type Notification = {
	title: string | null;
	content: React.ReactNode | string | null;
	actions: React.ReactNode | string | null;
	minHeight?: string;
}
function NotificationComponent(props: NotificationDto) {
	const [, setBackDropOpen] = useState(props.app.loading)
	const [notificationOpen, setNotificationOpen] = useState(false)

	const notification: Notification = useRef({
		title: null,
		content: null,
		actions: null,
	}).current

	useEffect(() => {
		setBackDropOpen(props.app.loading)
	}, [props.app.loading])

	useEffect(() => {
		if (props.app.error) {
			notification.title = props.app.error.data?.statusCode
			notification.content = (
				<Box sx={{textAlign: 'center'}}>
					{props.app.error.data?.message ?? props.app.error}
				</Box>
			)
			notification.minHeight = '150px'
			setNotificationOpen(true)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.app.error])

	useEffect(() => {
		if (props.contactAgent.comment.sent) {
			notification.title = props.contactAgent.comment.notification.title
			notification.content = (
				<Box>
					{props.contactAgent.comment.notification.content}
				</Box>
			)
			setNotificationOpen(true)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.contactAgent.comment.sent])

	const handleNotificationClose = () => {
		setNotificationOpen(false)
	}

	if (notification.actions === null) {
		notification.actions = (
			<React.Fragment>
				<Button variant="contained" onClick={handleNotificationClose}>Close</Button>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			{/*<Backdrop open={backDropOpen}>*/}
			{/*	<CircularProgress/>*/}
			{/*</Backdrop>*/}
			<CustomDialog open={notificationOpen} onClose={handleNotificationClose} {...notification}/>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	return {
		app: state.app,
		contactAgent: state.contactAgent,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		// onResetContactAgentSent: () => dispatch(actions.resetContactAgentSent()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent)