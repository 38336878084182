import { createTheme, ThemeOptions } from "@mui/material";
// import LinkBehavior from "./LinkBehavior";


function getDefaultTheme(): ThemeOptions {
  const baseFontColor = '#2A2F31';
  return {
    palette: {
      // mode: 'dark',
    },
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          color: baseFontColor,
        }
      },
      MuiLink: {
        // defaultProps: {
        //   component: LinkBehavior,
        // } as LinkProps,
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: baseFontColor,
          }
        }
      },
      MuiButtonBase: {
        // defaultProps: {
        //   LinkComponent: LinkBehavior,
        // },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            textTransform: 'uppercase',
            height: '64px',
            //background: '#FFC700',
            borderRadius: '24px',
            cursor: 'pointer',
            '&:disabled': {
              opacity: '0.3',
            },
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '32px',
            textAlign: 'center',
            color: baseFontColor,
            padding: '16px 47px',
          },
          contained: {
            background: '#FFC700',
            '&:hover': {
              background: '#FFC700',
            },
            '&:disabled': {
              background: '#FFC700',
            },
          },
          outlined: {
            background: '#FFFFFF',
            border: '4px solid #FFC700',
            borderRadius: '24px',
            '&:hover': {
              border: '4px solid #FFC700',
            },
          }
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardError: {
            color: 'rgb(250, 179, 174)',
            backgroundColor: 'rgb(24, 6, 5)',
          },
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            color: '#2A2F31',
            backgroundColor: '#ffffff',
          }
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            color: baseFontColor,
            backgroundColor: '#fff',
          }
        }
      },
      MuiModal: {
        styleOverrides: {
          root: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#2A2F31',
          }
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      }
    }
  };
}

export function getTheme(props: any) {
    return createTheme(getDefaultTheme());
}
