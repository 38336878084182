import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import { DialogTitle as MuiDialogTitle } from '@mui/material';

// import {makeStyles} from '@material-ui/core/styles';
// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
// import {green} from "@material-ui/core/colors";
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import Typography from '@material-ui/core/Typography';
// import DialogActions from "@material-ui/core/DialogActions";
// import Button from "@material-ui/core/Button";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: '100%',
//     },
//     button: {
//         marginRight: theme.spacing(1),
//     },
//     closeButton: {
//         position: 'absolute',
//         right: theme.spacing(1),
//         top: theme.spacing(1),
//         color: theme.palette.grey[500],
//     },

//     buttonProgress: {
//         color: green[500],
//         marginLeft: theme.spacing(1),
//     }
// }));

const DialogTitle = (props: any) => {
    const {children, onClose, ...other} = props;
    // todo: !!!!
    const classes = {
        root: undefined,
        button: undefined,
        closeButton: undefined,
        buttonProgress: undefined,
    }; // useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    {/* <CloseIcon/> */}
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const TermsModal = (props: any) => {
    // todo: !!!!
    const classes = {
        some: undefined,
        
    }; // useStyles();
    const termsClasses = ['terms'];
    if (props.isLoginPage) {
       termsClasses.push('termsLogin');
    }

    return (
        <div>
            <Dialog fullScreen open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title"
                    className={classes.some} PaperProps={{
                style: {
                    background: 'linear-gradient(180deg, #323334 0%, #121212 100%)',
                    boxShadow: 'none',
                },
            }}>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                </DialogTitle>
                <DialogContent className={termsClasses.join(' ')}>
                    Carwiser.com Terms of Service
                    <hr/>
                    <p>The Carwiser.com website is owned and operated by Boost Acquisition, Inc. (“Boost” or the
                        “Company”). By accessing or using this website, any of its pages and/or any of the services
                        referenced herein, you accept and agree to be bound by the Terms of Service set forth below.</p>
                    <h4>1. ACCEPTANCE OF TERMS.</h4>
                    <p>Carwiser.com (“Boost” “we,” “us,” or “our”) provides users with an online automotive information
                        and communications platform, as well as related services that can be accessed from or through
                        this website (collectively, “Services”). Please carefully read these Terms of Service before
                        using the Services. By accessing or using the Services, including this website, you accept and
                        agree to be bound by these Terms of Service (“Terms of Service”) and all applicable laws, rules,
                        and regulations associated with your use of the Services. If you do not agree to the Terms of
                        Service, you are not authorized to use this website or the Services. These Terms of Service also
                        apply to any co-branded or framed version of this website.</p>
                    <p>Your use of certain materials and features of this website and/or the Services may be subject to
                        additional terms and conditions which are incorporated herein by reference and become part of
                        the Terms of Service. By using those materials and features, you also agree to be bound by such
                        additional terms and conditions. Unless explicitly stated otherwise, any new features that
                        augment or enhance the current Services shall be subject to the Terms of Service.</p>
                    <p>This website is controlled and operated by Boost from its offices within the United States. Boost
                        makes no representation that information or materials available on this website are appropriate
                        or available for use in other locations, and access to this website from territories where its
                        contents are illegal is prohibited. Those who choose to access this website from other locations
                        do so at their own initiative and are responsible for compliance with applicable local laws.</p>
                    <h4>2. SERVICES</h4>
                    <p>Boost offers a Service whereby you may request conditional offers to purchase your used motor
                        vehicle (excluding certain types of vehicles) from certain participating dealers in the Boost
                        network (each offer, an “Offer”). Each Offer is for informational purposes only and does not
                        constitute, and should not be construed as, a binding offer by Boost, a dealer or any third
                        party to purchase your used motor vehicle. A participating dealer that may make an Offer is
                        referred to as a “Participating Dealer.” None of the Participating Dealers are owned or operated
                        by Boost or otherwise act on behalf of Boost. When you request an Offer through the Service, you
                        must have possession of the actual vehicle listed, with the ability to transfer valid title as
                        set forth herein. You are required to provide certain identifying and contact information, which
                        must accurately identify yourself so Participating Dealers can communicate directly with you. By
                        using the Service, you represent, warrant, and covenant that you are not: (i) a motor vehicle
                        dealer or (ii) listing a vehicle for sale in your capacity as an owner, employee, or
                        representative of a motor vehicle dealer. For clarity, if you are a motor vehicle dealer, you
                        may not use the Service to obtain an on behalf of a customer.</p>
                    <p>The Service enables Participating Dealers to obtain information about vehicles for which Offers
                        have been requested and, if applicable, to contact a seller to make a Offer. We are not a party
                        to, or otherwise guarantee the terms of, any Offer or any transaction resulting therefrom. You
                        alone are responsible for any transaction you engage in resulting from an Offer from a
                        Participating Dealer. Although Participating Dealers have agreed to abide by certain customer
                        service standards, we are not responsible for any potential fraud or other misconduct by a
                        Participating Dealer in connection with the Service. As a seller, you should exercise caution
                        and good judgment when completing any transaction, including those involving the Service.</p>
                    <p>The Service is not available in all areas and not all vehicles are eligible. The Service may not
                        be used to sell or to trade-in certain categories of vehicles, including commercial vehicles;
                        vehicles with a government, police, fire or livery history; motorcycles; recreational vehicles;
                        reconstructed or salvage vehicles; vehicles without a valid VIN; “grey market” vehicles;
                        vehicles older than seventeen (17) model years; vehicles with altered drivelines or bodies or
                        illegal or non-functioning emissions equipment; flood, hail or fire damaged vehicles; vehicles
                        with substantial mechanical or electrical damage; vehicles registered at an auto auction or
                        offered for sale on a wholesale website within forty five (45) days prior to submitting your
                        request for an Offer; vehicles that are valued at less than $1,000; and any other category of
                        vehicle that we, in our sole discretion, believe may adversely impact the operation of the
                        Service (each, a “Restricted Vehicle”). If you solicit an Offer for any Restricted Vehicle using
                        the Service, you may not receive an Offer for such vehicle and, in the event that you do receive
                        an Offer for such vehicle, you acknowledge and agree that such Offer may be invalidated and that
                        no Participating Dealer is required to purchase such vehicle from you.</p>
                    <p>You acknowledge that if you provide any false, incomplete, or inaccurate information, including
                        vehicle information or personal contact information, your Offer may be invalidated by the
                        Participating Dealer. By requesting an Offer, you agree that Boost, its service providers,
                        Participating Dealers, and/or affinity partners may use the personal contact information you
                        provided, including your telephone number and email address, to communicate with you about the
                        Service or other products and services that may be of interest to you. By requesting an Offer
                        through the Service, you agree to use the email address or any other contact information of the
                        Participating Dealer(s) making an Offer only for purposes of communicating with them about the
                        potential sale of that vehicle.</p>
                    <p>Although a Participating Dealer is not obligated to purchase your vehicle for any price, you can
                        try to negotiate a more favorable price or a trade-in value for your vehicle and, of course, you
                        are always free to sell or trade in your vehicle outside of the Service. The sale price that you
                        could receive for your vehicle in the open market or from another source may be higher than the
                        amount of your Offer received through the Service.</p>
                    <p>To use the Service to receive an Offer from a Participating Dealer: (1) your vehicle must be
                        located in a market in which the Service is offered and registered in the same state as the
                        location of the applicable Participating Dealer; (2) you must be the registered owner of the
                        vehicle; (3) you must possess a valid driver’s license or other form of valid government-issued
                        photo ID; (4) you must provide accurate, complete, and truthful personal contact information and
                        responses to all questions included in the online questionnaire, including those relating to the
                        description and condition of your vehicle; and (5) you must deliver your vehicle, with a valid
                        registration and either clear and unencumbered title in your name or complete documentation
                        regarding any lease obligations or liens on your vehicle (including, for example, contact
                        information for your lender or leasing company, relevant account information and the current
                        loan payoff amount or lease early termination fee for your vehicle), to the Participating Dealer
                        for inspection by, or behalf of, the Participating Dealer. For Participating Dealers who may
                        offer a mobile pick-up service, delivery may actually occur at your home or other mutually
                        agreed upon location.</p>
                    <p>You are responsible for the information contained in the vehicle questionnaire, the content of
                        all communications you transmit through the Service, and any other information you provide. We
                        may perform random or selective quality assurance tests to confirm that individuals who request
                        Offers through the Service are not automobile dealers and are prepared to sell such vehicles
                        through the Service. By using the Service, you agree to cooperate in these quality assurance
                        tests. If the tests reveal, or we otherwise learn, that a seller is violating these Terms of
                        Service or engaging in other unfair or deceptive practices, Boost may, in its sole discretion,
                        deny that seller use of the Service.</p>
                    <p>For the avoidance of doubt, Boost is solely a research and communications platform. Boost is not
                        a vehicle purchaser, dealer, broker or agent for vehicle sellers or dealers, nor a provider of,
                        or broker or agent for, other automotive-related products or services offered by third parties.
                        Boost receives a fee from the participating dealers or third-party service providers in
                        connection with the Services.</p>
                    <p>THE BOOST ENTITIES DO NOT MAKE ANY REPRESENTATION OR WARRANTY THAT YOU WILL BE ABLE TO SELL YOUR
                        VEHICLE, OBTAIN AN ACCEPTABLE PRICE FOR YOUR VEHICLE, RECEIVE ANY INQUIRIES OR SOLICITATIONS
                        FROM POTENTIAL PARTICIPATING DEALERS, OR RECEIVE OFFER FOR, YOUR VEHICLE FOR SALE. IN THE EVENT
                        THAT ANY CLAIMS OR DISPUTES ARISE OUT OF AN ACTUAL OR POTENTIAL TRANSACTION INVOLVING THE
                        xSERVICE, YOU AGREE TO LOOK SOLELY TO THE PARTICIPATING DEALER FOR YOUR REMEDY AND NOT TO THE
                        BOOST ENTITIES.</p>
                    <h4>3. USER CONDUCT</h4>
                    <p>You are authorized by Boost to access and use the Services, including the information on this
                        website, solely for your personal, non-commercial use provided that you are at least 18 years of
                        age. The information and materials displayed on this website may not otherwise be copied,
                        transmitted, displayed, distributed, downloaded, licensed, modified, published, posted,
                        reproduced, used, sold, transmitted, used to create a derivative work, or otherwise used for
                        commercial or public purposes without Boost’s express prior written consent. Any use of data
                        mining, robots or similar data gathering or extraction tools or processes in connection with
                        this website, and any reproduction or circumvention of the navigational structure or
                        presentation of this website or its content, is strictly prohibited. You agree not to use the
                        Services, including this website, for any unlawful purpose.</p>
                    <p>In order to access certain features of this website you may be required to register. You are
                        responsible for maintaining the confidentiality of your password and account and are fully
                        responsible for all activities that occur under your password or account. You agree to
                        immediately notify Boost in writing of any unauthorized use of your password or account or any
                        other breach of security, and ensure that you exit from your account at the end of each session.
                        Boost is not liable for any loss or damage arising from your failure to comply with this
                        section. You agree not to modify the Services or use modified versions of the Services (except
                        if modified by Boost), including for the purpose of obtaining unauthorized access to the
                        Services. You agree not to access the Services by any means other than through the interface
                        that is provided by Boost for use in accessing the Services.</p>
                    <h4>4. TELEPHONIC COMMUNICATIONS WITH BOOST AND PARTICIPATING DEALERS</h4>
                    <p>You acknowledge that telephone calls to or from Boost or participating dealers may be monitored
                        and recorded and you agree to such monitoring and recording.</p>
                    <p>You verify that any contact information provided to Boost or a participating dealer, including,
                        but not limited to, your name, mailing address, email address, your residential telephone
                        number, and/or your mobile telephone number, is true and accurate. You verify that you are the
                        current subscriber or owner of any telephone number that you provide.</p>
                    <p>You agree to indemnify, defend and hold Boost and participating dealers harmless from and against
                        any and all claims, losses, liability, costs and expenses (including reasonable attorneys’ fees)
                        arising from your voluntary provision of contact information (including a telephone number) that
                        is not your own, and/or from your violation of any federal, state or local law, regulation or
                        ordinance.</p>
                    <p>You acknowledge that by voluntarily providing your telephone number(s) to Boost or participating
                        dealers, you expressly agree to receive prerecorded voice messages and/or autodialed calls or
                        text messages related to pricing and offers, your account, any transaction with us, and/or your
                        relationship with Boost or participating dealers. You also agree to be contacted at email and
                        mailing addresses provided by you. Consent to receive automated marketing calls/texts is not a
                        condition of purchasing or leasing any vehicle or service. Calls or text messages may be sent by
                        Boost or participating dealers even if your telephone number is registered on any state or
                        federal Do Not Call list. You acknowledge that we may add to or withdraw dealers from our list
                        at any time, and you consent to be contacted, as explained above, by these dealers
                        notwithstanding the date of the start of your relationship with Boost. Your consent to be
                        contacted applies to participating dealers that may have existed at the start of your
                        relationship with Boost and that may thereafter be added to our participating dealers list. You
                        acknowledge that you may incur a charge for calls or text messages by your telephone carrier and
                        that neither Boost nor participating dealers are responsible for these charges.</p>
                    <p>You may opt-out of automated calls or text messages at any time. To opt-out of text messages,
                        reply STOP to any text message you receive or follow other opt-out instructions included with
                        the text message. You acknowledge and agree that you may receive a text message confirming your
                        opt-out. To opt-out of automated telephone calls from Boost (but not text messages), call
                        (888)-BOOST. To opt-out of receiving automated telephone calls or texts from participating
                        dealers, please contact the dealer directly. Please allow up to thirty (30) days to process any
                        opt-out request. Please note that if you opt out of automated calls, we reserve the right to
                        make non-automated calls to you relating to your account, any transaction, or your relationship
                        with us. Your obligations under this Section shall survive termination of these Terms of
                        Service.</p>
                    <h4>5. PRIVACY</h4>
                    <p>Boost is committed to respecting your privacy and protecting your personally identifiable
                        information. Upon your request, Boost shares the information you enter on this website with
                        participating dealers and/or buyers in order to provide you with the information you requested,
                        and with other service providers associated with the Services. Boost account data and certain
                        other information about you and that you enter and/or we collect through your use of this
                        website are subject to the Boost Privacy Policy at <a
                            href={'http://www.carwiser.com/privacy.html'}
                            rel="noopener noreferrer"
                            target={'_blank'}>http://www.carwiser.com/privacy.html</a>. You understand that through your
                        use of the Services, including this website, you consent to the collection and use (as set forth
                        in the Carwiser Privacy Policy) of this information. Please review our Privacy Policy for
                        further information on our data collection and use practices.</p>
                    <h4>6. NO COMMERCIAL USE OF SERVICES</h4>
                    <p>You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial
                        purposes, any portion or use of, or access to, the Services.</p>
                    <h4>7. RIGHT TO DENY ACCESS AND TO MODIFY THE SERVICES</h4>
                    <p>Boost reserves the right to deny use of, or access to, the Services to you and/or anyone for any
                        or no reason. Boost also reserves the right at any time and from time-to-time to modify or
                        discontinue, temporarily or permanently, the Services (or any part thereof) with or without
                        notice. You agree that Boost shall not be liable to you or to any third party for any
                        modification, suspension or discontinuance of the Services (or any part thereof).</p>
                    <h4>8. INDEMNIFICATION</h4>
                    <p>You will indemnify, defend and hold harmless Boost and its subsidiaries, affiliates, partners,
                        officers, directors, employees, and agents (collectively, “Boost Entities”) from all claims,
                        whether actual or alleged (collectively, “Claims”), that arise out of or in connection with a
                        breach of these Terms of Service, use of the Services, including any content you submit, post,
                        transmit, modify or otherwise make available through the Services, and/or any violation of law
                        and/or the rights of any third party. You are solely responsible for defending any Claim against
                        a Boost Entity, subject to such Boost Entity’s right to participate with counsel of its own
                        choosing, at its own expense, and for payment of all judgments, settlements, damages, losses,
                        liabilities, costs, and expenses, including reasonable attorneys’ fees, resulting from all
                        Claims against a Boost Entity, provided that you will not agree to any settlement that imposes
                        any obligation or liability on a Boost Entity without its prior express written consent.</p>
                    <h4>9. DISCLAIMER OF WARRANTIES</h4>
                    <p>THE SERVICES, INCLUDING ALL INFORMATION AND CONTENT ON OR OTHERWISE RELATED IN ANY WAY TO THE
                        SERVICES, THIS WEBSITE OR ANY THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LINKED TO OR FROM THIS
                        WEBSITE, ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
                        THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TIMELINESS, AND
                        NONINFRINGEMENT. IN ADDITION, BOOST DOES NOT WARRANT AGAINST DEFECTS IN ANY VEHICLE, AND DOES
                        NOT MAKE ANY WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING REPRESENTATIONS,
                        PROMISES, OR STATEMENTS AS TO THE CONDITION, FITNESS, OR MERCHANTABILITY OF ANY VEHICLE OR
                        SERVICE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM BOOST OR THROUGH OR
                        LINKED FROM THE SERVICES SHALL CREATE ANY WARRANTY EXPRESS OR IMPLIED. Boost is not responsible
                        for making repairs to any vehicle. If you have complaints or concerns about defects or repairs,
                        please contact the dealer, seller, or manufacturer directly.</p>
                    <h4>10. LIMITATION OF LIABILITY; WAIVER</h4>
                    <p>IN NO EVENT SHALL THE BOOST ENTITIES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                        CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE OF
                        DATA, INFORMATION, AND/OR CONTENT, OR OTHER INTANGIBLE LOSSES ARISING OUT OF, OR IN CONNECTION
                        WITH THE SERVICES, INCLUDING ALL INFORMATION AND CONTENT ON OR OTHERWISE RELATED IN ANY WAY TO
                        THIS WEBSITE OR ANY THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LINKED TO OR FROM THIS WEBSITE, OR
                        ANY VEHICLE REFERENCED THEREIN. WITHOUT LIMITING THE FOREGOING, ANY INFORMATION AND CONTENT
                        DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT YOUR OWN
                        DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS
                        AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS,
                        DOWNLOAD OR DISPLAY DEVICE, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
                        INFORMATION AND CONTENT. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH ANY SERVICE
                        AND/OR THIS WEBSITE IS TO STOP USING THE SERVICE AND/OR WEBSITE.</p>
                    <p>YOU HEREBY IRREVOCABLY WAIVE ANY CLAIM (WHETHER FOR INJURY, ILLNESS, DAMAGE, LIABILITY AND/OR
                        COST) AGAINST THE BOOST ENTITIES ARISING OUT OF YOUR USE OF OR INABILITY TO USE, OR IN
                        CONNECTION WITH, THE SERVICES, INCLUDING ANY INFORMATION AND CONTENT ON THIS WEBSITE OR ANY
                        THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LINKED TO OR FROM THIS WEBSITE, INCLUDING ANY CONTENT
                        YOU PROVIDE TO THIRD PARTIES (INCLUDING PERSONALLY IDENTIFIABLE INFORMATION).</p>
                    <h4>11. EXCLUSIONS AND LIMITATIONS</h4>
                    <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN IMPLIED WARRANTIES, OR THE LIMITATION OR
                        EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSIONS AND
                        LIMITATIONS MAY NOT APPLY TO YOU.</p>
                    <h4>12. NOTICE</h4>
                    <p>Boost may provide you with notices by email, regular mail, SMS, MMS, text message, postings on
                        the Services/website, or other reasonable means now known or hereafter developed. You
                        acknowledge and agree that Boost will have no liability associated with or arising from your
                        failure to maintain and supply Boost with accurate contact information about yourself, including
                        your failure to receive important information and updates about the Services or this
                        website.</p>
                    <h4>13. TRADEMARK INFORMATION AND INTELLECTUAL PROPERTY</h4>
                    <p>You agree that all of Boost’s trademarks, trade names, service marks, logos, brand features, and
                        product and Service names are trademarks and the property of Boost, and that you will not
                        display or use any of the foregoing without Boost's prior written approval in each instance.</p>
                    <p>You agree that the Services contain proprietary information protected by applicable intellectual
                        property and other laws in favor of Boost. You further agree that content and information
                        presented to you through the Services is protected by copyrights, trademarks, service marks,
                        patents and/or other proprietary rights and laws.</p>
                    <h4>14. COPYRIGHT OR INTELLECTUAL PROPERTY COMPLAINTS</h4>
                    <p>Boost respects the intellectual property of others. If you believe that your work has been copied
                        in a way that constitutes copyright infringement, or your intellectual property rights have been
                        violated, please provide us with the following information:</p>
                    <ul>
                        <li>a physical signature of the person authorized to act on behalf of the owner of the copyright
                            or other intellectual property right;
                        </li>
                        <li>a description of the copyrighted work or other intellectual property that you claim has been
                            infringed or violated;
                        </li>
                        <li>a description of where the material that you claim is infringing is located on our
                            website;
                        </li>
                        <li>your address, telephone number, and email address;</li>
                        <li>a statement by you that you have a good faith belief that the disputed use is not authorized
                            by the copyright or intellectual property owner, its agent, or the law;
                        </li>
                        <li>a statement by you, made under penalty of perjury, that the above information in your notice
                            is accurate and that you are the copyright or intellectual property owner or authorized to
                            act on the copyright or intellectual property owner's behalf.
                        </li>
                    </ul>
                    <p>Please send the foregoing information to our agent for notice of claims of copyright or other
                        intellectual property infringement to Contact listed below.</p>
                    <p>
                        Boost Acquisition,<br/> Inc.Attn: Legal Department<br/>591 Camino de la Reina, Suite 400<br/>San
                        Diego, CA 92108
                    </p>
                    <h4>15. CHOICE OF LAW.</h4>
                    <p>Except as otherwise expressly provided in the Boost Arbitration Agreement (which applies the
                        Federal Arbitration Act), the relationship between you and Boost are governed by the laws of the
                        State of California without regard to its conflict/choice of law provisions.</p>
                    <h4>16. GENERAL INFORMATION</h4>
                    <p>These Terms of Service may be amended from time to time without notice in Boost’s sole
                        discretion. Any changes to the Terms of Service will be effective immediately upon the posting
                        of the revised Terms of Service on this website. The Terms of Service, including any agreements
                        and terms incorporated by reference herein, constitute the entire agreement between you and
                        Boost and govern your use of the Services, including this website, and supersede any prior
                        version of these Terms of Service between you and Boost with respect to the Services. You agree
                        that no agency relationship is created between you and Boost as a result of these Terms of
                        Service or your access to and/or use of the Services. The failure of Boost to exercise or
                        enforce any right or provision of the Terms of Service shall not constitute a waiver of such
                        right or provision. If any provision of the Terms of Service is found by a court of competent
                        jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to
                        give effect to the parties' intentions as reflected in the provision, and the other provisions
                        of the Terms of Service remain in full force and effect. You agree that, except as otherwise
                        expressly provided in these Terms of Service, there shall be no third-party beneficiaries to
                        these Terms of Service. The headings in these Terms of Service are for convenience only and have
                        no legal or contractual effect. As used in these Terms of Service, the word “including” is a
                        term of enlargement meaning “including without limitation” and does not denote exclusivity, and
                        the words “will,” “shall,” and “must” are deemed to be equivalent and denote a mandatory
                        obligation or prohibition, as applicable. All definitions apply both to their singular and
                        plural forms, as the context may require. Please report any violations of the Terms of Service
                        to <a href={'mailto:legal@boostacquisition.com'}>legal@boostacquisition.com</a>. Questions
                        regarding these Terms of Service should be sent to <a
                            href={'mailto:legal@boostacquisition.com'}>legal@boostacquisition.com</a>.</p>
                    <p>THE BOOST ENTITIES DO NOT MAKE ANY REPRESENTATION OR WARRANTY THAT YOU WILL SELL YOUR VEHICLE,
                        OBTAIN AN ACCEPTABLE PRICE FOR YOUR VEHICLE, RECEIVE LEGITIMATE INQUIRIES OR SOLICITATIONS FROM
                        QUALIFIED BUYERS, OR RECEIVE ANY INQUIRIES REGARDING YOUR VEHICLE FOR SALE.</p>
                    Carwiser.com Terms of Privacy
                    <hr/>
                    <h4>Please read below to understand your rights and how we use the information we gather</h4>
                    <h4>Effective Date: 7/1/2020.</h4>
                    <p>This Privacy Notice (“Notice”) applies to this website or mobile application and the websites or
                        mobile applications of Boost Acquisition, Inc., and its subsidiaries and affiliates
                        (collectively “Boost”, “we”, “us” or “our”) that display this Notice (each a “Site” and
                        collectively, the “Sites”). By visiting the Sites, you are consenting to our collection, use,
                        disclosure, retention, and protection of information about you and devices you use as described
                        in this Notice.</p>
                    <p>This Notice only applies to the Sites and does not apply to information collected or received by
                        other means.</p>
                    <p>Our Sites are not directed to children under the age of 18. We do not knowingly collect personal
                        information from children under age 18. This Notice does not apply to anonymized or aggregated
                        data that does not allow us or third parties to identify or contact you.</p>
                    <h4>1. What Information Does Boost Gather?</h4>
                    <p><span>Information You Give Us.</span> We may collect and retain any information from you or your
                        devices provided to us when you visit a Site, including when you:</p>
                    <ul>
                        <li>use our service or mobile application;</li>
                        <li>register for an account;</li>
                        <li>communicate with us, such as to provide feedback, request support, or ask for additional
                            information; and
                        </li>
                        <li>subscribe to content we offer, such as newsletters, alerts, etc.</li>
                    </ul>
                    <p>We may collect information such as your first and last name, home or other physical address,
                        email address, zip code, telephone number, your automobile’s make, model, trim, model year,
                        mileage, color, options, vehicle identification number (“VIN”), license plate number, or
                        information about your interactions with us or others, and other information.</p>
                    <p><span>Social Media.</span> You may use social networks or other online services to access our
                        Sites. When you do so, information from those services may be made available to us. By
                        associating a social network account with our Sites, you agree that we may access and retain
                        that information in accordance with the policies of the social network or other online service
                        and this Notice. For example, we may be able to access account or profile information that you
                        have provided to the social network or information about your interactions with the social
                        network to make information available to us (such as when commenting on a blog post or using a
                        sign-on service, such as Facebook Connect).</p>
                    <p><span>Automatically Collected Information.</span> We may collect information automatically when
                        you visit our Sites or use our mobile applications, such as:
                        <ul>
                            <li>your IP address; the type of browser, devices and operating systems you use;</li>
                            <li>identifiers associated with the device(s) you use to access our Sites;</li>
                            <li>the pages you visit, vehicles you view or configure, and the features you use, including
                                dates and times;
                            </li>
                            <li>if you navigated from or navigate to another website, the address of that website; and
                            </li>
                            <li>information regarding your internet service provider.</li>
                        </ul>
                    </p>
                    <p>In addition, we may collect information about your activities on our Sites via first and
                        third-party cookies, clear GIFs or web beacons, or through other identifiers or technologies,
                        including similar technologies as they may evolve over time. We refer to these technologies
                        collectively as Metrics Tools.</p>
                    <p>We may allow third parties to use Metrics Tools on our Sites. The information collected by
                        Metrics Tools may be shared with and used by us, by others acting on our behalf, or by third
                        parties subject to their own privacy policies. Information collected by Metrics Tools may be
                        used on this Site or on other websites or services, including those that may not be operated by
                        us.</p>
                    <p><span>Email.</span> We may collect information regarding the effectiveness of our email and other
                        communications with you. For example, we may know if you follow a link in an email we send to
                        you.</p>
                    <p><span>Mobile.</span> We may collect session and geolocation information from your mobile device.
                        Geolocation information includes data such as your device’s physical location and may include
                        GPS-based, WiFi-based or cell-based location information.</p>
                    <p><span>Information from Other Sources.</span> We may obtain information about you from affiliates,
                        partners, automobile dealers and other third parties. This information may include information
                        about your use of this Site or our services, your use of other websites, your interactions with
                        or purchases from automobile dealers, your interests and preferences and other information about
                        you or your household. We may combine the information we obtain from third parties with
                        information that we or our affiliates have collected about you.</p>
                    <h4>2. How Does Boost Use Information About Me?</h4>
                    <p>We and others acting on our behalf may use the information that we collect or receive to operate
                        our business, including our Sites, as described below:</p>
                    <p><span>Operate and Support our Sites and Services.</span> We use the information that we gather in
                        order to operate our Sites and our services. For example, we may use the information that we
                        collect or receive to provide support and assistance that you request or to diagnose or address
                        technical problems in the operation of our Sites or any of our services. If you establish an
                        account with us, we may use information about you to manage or support your account. We may
                        identify your use of our Sites across devices, and we may use information we collect from you
                        and receive from others to optimize your use of the Sites and our services as you use different
                        devices.</p>
                    <p><span>Improving and Evolving our Services.</span> We constantly evaluate and improve our Sites
                        and services, including developing new products or services and use the information we gather to
                        do so.</p>
                    <p><span>To Allow You to Connect with Dealers and Vehicle Manufacturers.</span> When you use our
                        service, and after you provide your contact information to request offers or information, we may
                        use the information you provide us to connect you with a limited number of automobile dealers
                        and vehicle manufacturers. These dealers and vehicle manufacturers may provide you with a
                        savings certificate, offers and/or incentives for new or used vehicles or other products or
                        services. When you choose to share your contact information with dealers, your direct
                        interactions with those dealers will not be controlled by this Notice. You should review the
                        applicable notices of those dealers to understand how they may use your information.</p>
                    <p><span>To Allow You to Connect with Lending Institutions.</span> Our Sites and services may permit
                        you to provide information relevant to assessing your creditworthiness or designed to facilitate
                        financing (for example, in connection a potential vehicle lease or purchase). Your direct
                        interactions with lending institutions will not be controlled by this Notice. You should review
                        the applicable notices of those lending institutions to understand how they may use your
                        information.</p>
                    <p><span>Advertising and Promotions.</span> We may use the information we gather to offer, provide,
                        or personalize products and services from us and third parties. For example, we may customize
                        content, advertising, promotions and incentives to reflect your preferences, interests, or prior
                        interactions with us and others.</p>
                    <p><span>Other Contacts.</span> We may contact you through telephone, text, or chat for other
                        purposes, as permitted by law.</p>
                    <p>This Notice only addresses our own information practices. This Notice does not apply to
                        information you share with third parties, including but not limited to dealers and lending
                        institutions, even if we link to those third parties from a Site. These third parties may have
                        their own privacy policies governing their use of information that you can access from their
                        websites. Our services may be offered through third party websites or mobile applications
                        (“Partner Sites”). This Notice does not apply to Partner Sites, unless otherwise indicated on
                        the Partner Site.</p>
                    <p>Please note that other parties may collect personally identifiable information about your online
                        activities over time and across different websites when you use our Site.</p>
                    <h4>3. When Does Boost Share Information?</h4>
                    <p>We only share information about you as described or permitted by this Notice, unless you consent
                        to other sharing. We may share information about you in the following ways:</p>
                    <p><span>Corporate Affiliates.</span> We may share information with our corporate affiliates for
                        their everyday business purposes, to provide services or to perform marketing. We may also
                        participate in joint ventures with others and we may share information as part of that joint
                        venture.</p>
                    <p><span>Partner Sites.</span> We may share the information we gather with Partner Sites (and the
                        owners of those sites). For example, we may share information for determining or verifying our
                        economic rights and obligations.</p>
                    <p><span>Service Providers.</span> We may share information with third party service providers who
                        help us operate our business or provide services on our behalf, including third parties who help
                        us operate our Sites. These service providers include analytics companies and advertising
                        networks that provide Metrics Tools on our Sites.</p>
                    <p><span>Promotional.</span> We may share information with third parties to help us promote our
                        products and services, or to help us customize advertisements, offers, or other communications
                        to you.</p>
                    <p><span>Automobile Dealers and Vehicle Manufacturers.</span> We may share information with
                        automobile dealers, manufacturers and other providers of automobile related products and
                        services. For example, we may share information with our participating dealers to allow you to
                        communicate with them, and them to communicate with you when you elect to share your information
                        with them.</p>
                    <p><span>Business Transfer.</span> We may share information we have collected from you in connection
                        with the sale or merger of our business or the transfer of assets.</p>
                    <p><span>Protection of Ourselves and Others.</span> We may use and share the information we gather
                        to enforce our rights and protect our property and those of our affiliates, customers and
                        members of the public, to comply with applicable law, legal process, legal advice and for
                        preventing fraud, theft, and injury to you, us or others.</p>
                    <h4>4. What Choices Do I Have Regarding My Information?</h4>
                    <p>You may limit and control the information provided to us in several ways. You may not be able to
                        use all features of our Sites if you limit the information you share with us.</p>
                    <p>For example, you can choose not to access our Sites through your social media account. You may
                        also be able to limit the information provided to us by third party social media providers by
                        altering your privacy settings with those providers. You may unsubscribe from promotional emails
                        from us by following the unsubscribe link included in each such email.</p>
                    <p>Your browser and your device may provide you the option to limit the use of cookies or other
                        Metrics Tools. You should consult documentation for your browser or device for more information.
                        Your mobile device may have settings that allow you to prevent sharing geolocation information
                        with us. You should consult your mobile device’s settings or help documentation for more
                        information about exercising these options.</p>
                    <p>Boost uses Google Analytics, which helps us better understand our audience and target
                        communications and advertisements based on demographic or interest-based information. You may
                        learn more about opting out of certain Google advertising initiatives <a
                            href={'https://support.google.com/ads/answer/2662922?hl=en'}
                            rel="noopener noreferrer"
                            target={'_blank'}>here</a> and <a href={'https://tools.google.com/dlpage/gaoptout/'}
                            rel="noopener noreferrer"
                                                              target={'_blank'}>here</a>. You may also be able to opt
                        out of certain targeted behavioral advertising via the Network Advertising Initiative’s <a
                            href={'http://optout.networkadvertising.org/?c=1#!/'} rel="noopener noreferrer" target={'_blank'}>opt-out.</a></p>
                    <p>Please note that you may still receive advertising even after opting out, but that advertising
                        may not be tailored to you or your interests.</p>
                    <h4>5. How Can I Access and Update My Information?</h4>
                    <p>If you are a registered user on carwiser.com, you may access, update and change certain
                        information we have collected about you by accessing the “My Account” tab after signing into
                        your account on www.carwiser.com.</p>
                    <h4>6. How Does Boost Address California Privacy Rights?</h4>
                    <p>This part of our Notice applies to consumers who reside in the State of California.</p>


                    <p><span>Personal Information We Collect about California Consumers</span></p>
                    <p>
                        We describe the Personal Information we have collected from consumers in the twelve (12) months
                        preceding the effective date of this Notice in the part of our Notice titled, <span
                        className={'span2'}>“What Information Does Boost Gather?”</span>. The information we collect
                        includes the following:
                        <ul>
                            <li>Identifiers such as name, address, e-mail address, phone number and other identifiers
                                that we describe above.
                            </li>
                            <li>The following categories of Personal Information are described in California Civil Code
                                § 1798.80(e): (1) the Personal Information listed in the preceding bullet point as
                                “identifiers,” (2) vehicle identification numbers (when linked to identified persons),
                                vehicle information such as model and year, vehicle registration information, and
                                license plate numbers, (3) information you provide or that we record in connection with
                                customer support calls or complaints, (4) demographic information such as age range and
                                gender, and (5) the other information that identifies, relates to, describes, or is
                                capable of being associated with, a particular individual that we describe in the part
                                of our Notice titled, <span className={'span2'}>“What Information Does Boost Gather?”</span>.
                            </li>
                            <li>Characteristics of protected classifications under California or federal law, such as
                                military service, sex or age range. Commercial information, including information about
                                records of personal property, orders, purchases, or usage of services in connection with
                                Boost Services.
                            </li>
                            <li>Internet or other electronic network activity information, including, but not limited
                                to, browsing history, search history, usage of, and information regarding your
                                interaction with our Sites.
                            </li>
                            <li>Geolocation data. When you use our Sites, it may access, collect, monitor, and/or
                                remotely store session and geolocation information from your device to connect you with
                                dealers in your area. Geolocation information includes data such as your device’s
                                physical location and may include GPS-based, WiFi-based or cell-based location
                                information.
                            </li>
                            <li>Audio information may be collected when you place a call with Boost’s customer service
                                call centers for quality control purposes.
                            </li>
                            <li>Inferences drawn from (1) the information we collect when you utilize our Sites, and (2)
                                information about Boost user preferences and behavior, which we use for product and
                                service enhancement and optimization purposes.
                            </li>
                        </ul>
                    </p>
                    <p>For information regarding the purposes for which we collect and use the Personal Information we
                        collect, please see the section of our Notice entitled <span className={'span2'}>“How Does Boost Use Information About Me.”</span>
                    </p>
                    <p><span>Your California Privacy Rights</span></p>
                    <p>
                        If you are a California consumer, you have the following rights. We will honor requests received
                        to the extent required by applicable law and within the time provided by law.
                        <strong>(a) Right to Access and Information regarding Personal Information.</strong> You have
                        the right to request that we disclose to you the categories and specific pieces of Personal
                        Information we have collected about you. Specifically, you have the right to request that we
                        disclose the following to you, in each case in the twelve-month period preceding your request:
                        <ul>
                            <li>the categories of Personal Information we have collected about you;</li>
                            <li>the categories of sources from which the Personal Information is collected;</li>
                            <li>our business or commercial purpose for collecting or selling Personal Information;</li>
                            <li>the categories of third parties with whom we share Personal Information;</li>
                            <li>the specific pieces of information we have collected about you;</li>
                            <li>the categories of Personal Information about you that we disclosed for a business
                                purpose.
                            </li>
                        </ul>
                    </p>
                    <p>
                        We will deliver Personal Information that we are required by law to disclose to you in the
                        manner required by law within 45 days after receipt of a verifiable request, unless we notify
                        you that we require additional time to respond, in which case we will respond within such
                        additional period of time required by law. We may deliver the Personal Information to you
                        through your account, if you maintain an account with TrueCar, or, if not, electronically. If
                        electronically, then we will deliver the information in a portable and, to the extent
                        technically feasible, in a readily useable format that allows you to transmit the information
                        from one entity to another without hindrance.<br/>
                        <strong>(b) Right to Deletion of Personal Information.</strong> You have the right to request
                        that we delete Personal Information about you that we have collected from you.<br/>
                        <strong>(c) Right to Opt Out.</strong> You have the right to opt out of the disclosure of
                        Personal Information about you to third parties for monetary or other valuable consideration. To
                        opt-out, email <a
                        href={'mailto:privacy@boostacquisition.com'}>privacy@boostacquisition.com</a> with your request.<br/>
                        <strong>(d) Right to Non-Discrimination.</strong> We may not discriminate against you because of
                        your exercise of any of the foregoing rights, or any other rights under the California Consumer
                        Privacy Act, including by:
                        <ul>
                            <li>denying you goods or services;</li>
                            <li>charging different prices or rates for goods or services, including through the use of
                                discounts or other benefits or imposing penalties;
                            </li>
                            <li>providing you a different level or quality of goods or services; or</li>
                            <li>suggesting that you will receive a different price or rate for goods or services or a
                                different level or quality of goods or services.
                            </li>
                        </ul>
                    </p>
                    <p>We may, however, charge different prices or rates, or provide a different level or quality of
                        goods or services, if that difference is reasonably related to the value provided to Boost by
                        your Personal Information.</p>
                    <p><span>Requests to Exercise Your Rights</span></p>
                    <p>
                        You may request to exercise the foregoing rights by:
                        <ul>
                            <li>Submitting a request via email to <a
                                href={'mailto:privacy@boostacquisition.com'}>privacy@boostacquisition.com</a>, or
                            </li>
                        </ul>
                    </p>
                    <p>You may designate an authorized agent to make a request on your behalf. Please note that we may
                        take steps to verify your identity before granting you access to information or acting on your
                        request to exercise your rights, as required or permitted by law. We may limit our response to
                        your exercise of the above rights as permitted by law.</p>
                    <p><span>Data Sharing for Direct Marketing Purposes</span></p>
                    <p>Residents of the State of California may request a list of all third parties to which we have
                        disclosed certain personal information (as defined by California law) during the preceding year
                        for those third parties’ direct marketing purposes. If you are a California resident and want
                        such a list, please contact us via the <strong>Contact</strong> section provided below.</p>
                    <p><span>Do Not Track Notice</span></p>
                    <p>At this time, there is no worldwide uniform or consistent industry standard or definition for
                        responding to, processing, or communicating Do Not Track signals. Thus, like many other websites
                        and online services, our Sites are currently unable to respond to Do Not Track Signals.</p>
                    <h4>7. How Does Boost Handle Privacy Disputes?</h4>
                    <p>By using this Site, you agree that any dispute arising out of or relating to the Sites, the
                        Sites’ content or the services or materials made available on the Sites, or regarding
                        information collected or shared about you, is subject to the Choice of Law, Venue, and Class
                        Action Waiver provisions in our Terms of Service.</p>
                    <h4>8. How Does Boost Protect the Security of the Personal Information it Collects?</h4>
                    <p>We use administrative, technical, and physical security designed to safeguard personal
                        information in our possession. We cannot guarantee the security of the information that we
                        collect and store. If you believe that information about you has been subject to unauthorized
                        disclosure, please let us know by emailing <a
                            href={'mailto:privacy@boostacquisition.com'}>privacy@boostacquisition.com</a>.</p>
                    <h4>9. How Does Boost Provide Updates to this Notice?</h4>
                    <p>When we make material changes to this Notice, we will update this web page and change the
                        Effective Date listed above.</p>
                    <h4>10. How Can Boost be Contacted Regarding Privacy Issues?</h4>
                    <p>You can contact us with questions or comments about our privacy practices or this Notice by
                        emailing us at <a
                            href={'mailto:privacy@boostacquisition.com'}>privacy@boostacquisition.com</a> or you can
                        contact us at:</p>
                    <p>
                        Boost Acquisition, Inc.<br/>
                        591 Camino de la Reina, Suite 400<br/>
                        San Diego, CA 92108
                    </p>

                </DialogContent>
                <DialogActions style={{justifyContent: 'center'}}>
                    <Button onClick={props.handleClose} color="primary" size={"small"} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default TermsModal;
