import axios from "../../axios";
import { RootState } from "../../app/store";
import { ActionTypes } from "./action-types";
import { getVehicles } from "./vehicles";
import { AppMessage, setAppStateMessage } from "./app";
import {RefreshOffersState} from "../reducers/refreshOffers";

export const refreshOffers = (data:any, cb: any) => {
    return async (dispatch: any, getState: any) => {
        
        const state = getState() as RootState;
        if (!state.auth.isAuthenticated) {
            // todo: error
            return ;
        }
        const currentStep = state.refreshOffers.step
        dispatch(setStep({step: "loading"}));
        try {
            const res = await axios.put(`vehicles/refresh`, {
                boostxchangeId: state?.vehicles?.selected?.xchangeId,
                ...data
            });
            if(res.data.success === false) {
                console.log('failed')
                if(res.data?.error === 'Not qualified') {
                    dispatch(setStep({step: "fail"}));
                    dispatch(getVehicles());
                }
                else{
                    throw Error('Request Failed')
                }
            } else {
                dispatch(getVehicles(cb));
            }
        }catch(e) {
            dispatch(setStep({step: currentStep}));
        }
    };
}

export const setStep = (state: RefreshOffersState) => {
    return {
        type: ActionTypes.REFRESH_OFFERS_SET_STEP,
        step: state.step
    };
};
