import {Box, Container, List, ListItem as MuiListItem, ListItemIcon as MuiListItemIcon, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import WelcomeCarwiserLogoLG from '../../img/welcome-to-carwiser.png'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {Button} from "../../components/form/Button";
import {useNavigate} from "react-router-dom";

type TitleProps = {
	content: React.ReactElement | string;
}
const Title = (props: TitleProps) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Box sx={{backgroundColor: '#ffffff'}}>
			<Container maxWidth="lg" disableGutters={isMobile ? false : true}>
				<Box sx={{
					mt: {xs: '16px', sm: '40px'},
					mb: {xs: '16px', sm: '20px'},
					height: {xs: '28px', sm: '40px'},
					display: 'flex',
					justifyContent: 'space-between',
				}}>
					<Box sx={{
						display: 'flex',
						alignItems: 'center'
					}}>
						<Typography variant="h3" sx={{
							fontSize: {xs: '18px', sm: '36px'},
							fontWeight: {xs: 600},
							color: '#2A2F31',
							position: 'relative',
						}}>
							{props.content}
						</Typography>
					</Box>
				</Box>
			</Container>
		</Box>
	)
}

type ListItemProps = {
	icon: React.ReactElement | string | null;
	text: React.ReactElement | string;
}
const ListItem = (props: ListItemProps) => {
	return (
		<MuiListItem sx={{
			mb: '4px',
			pl: '0px',
			'& .MuiListItemIcon-root': {
				minWidth: '43px'
			},
			alignItems: {xs: 'flex-start', md: 'center'},
		}}>
			<MuiListItemIcon sx={{
				color: '#2BACE2',
			}}>{props.icon}</MuiListItemIcon>
			<Box>{props.text}</Box>
		</MuiListItem>
	)
}


const WhyCarwiser = (props: any) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate();

	return (
		<>
			<Title content={'Why Carwiser?'}/>
			<Box sx={{backgroundColor: '#ffffff'}}>
				<Container maxWidth="lg" disableGutters={isMobile ? false : true}>
					<Box className="logo-block" sx={{
						display: "flex",
					}}>
						<Box component={'img'} src={WelcomeCarwiserLogoLG} sx={{
							maxWidth: '100%',
							height: 'auto',
						}}/>
					</Box>
					<Box className="content-block" sx={{
						mt: {xs: '16px', md: '20px'},
						mb: {xs: '32px', md: '40px'},
						color: '#2A2F31',
					}}>
						<Box className="title" sx={{
							fontWeight: {xs: 600, md: 500},
							fontSize: {xs: '16px', md: '24px'},
							lineHeight: {xs: '24px', md: '32px'},
							mb: '8px',
						}}>
							Welcome to your Carwiser account.
						</Box>
						<Box className="description" sx={{
							fontWeight: {xs: 400, md: 400},
							fontSize: {xs: '16px', md: '18px'},
							lineHeight: {xs: '24px', md: '28px'},
						}}>
							<Box sx={{mb: '4px'}}>
								Here you’ll find everything you need to get your vehicle sold. View all of your current offers, choose which one you’d like, and follow any next steps. We’re here to help throughout your car selling journey!
							</Box>
							<Box sx={{mb: '4px'}}>
								<List>
									<ListItem
										icon={<LocalAtmIcon/>}
										text={'Offers - Within your account you’ll see all the offers we were able to generate with our partners. Any offers that show in-progress will show the amount when available. Your Carwiser Agent will work with you for any additional information we need to get you the best offers possible.'}
									/>
									<ListItem
										icon={<CalendarTodayIcon/>}
										text={'Next Steps - Once you’ve chosen an offer, your account will show what next steps are needed to get your car sold.'}
									/>
								</List>
							</Box>
							<Box>
								Thanks for choosing Carwiser - the hassle-free way for you to get the best value for your vehicle.
							</Box>
						</Box>
					</Box>
					<Box className="action-block" sx={{mb: '40px', display: 'flex', justifyContent: {xs: 'center', md:'flex-end'}}}>
						<Button onClick={() => navigate('/dashboard')} variant="contained" sx={{
							width: '312px',
							fontSize: {xs: '20px'},
							lineHeight: {xs: '28px'},
							p: '18px 0',
							letterSpacing: '0.01em',
						}}>Back to Dashboard</Button>
					</Box>
				</Container>
			</Box>
		</>
	)
}

export default WhyCarwiser