import {Box, Link} from "@mui/material";

type MainLogoDto = {
    width?: string | undefined
    height?: string | undefined
}

function MainLogo(props: MainLogoDto) {
    return <Link className="logo" href="https://carwiser.com" target="_blank" rel="noopener noreferrer" sx={{
        background: `url(https://images.carwiser.com/carwiser/Carwiser_Logo_Full.svg) no-repeat center center`,
        backgroundSize: 'contain',
        display: 'block',
        width: props.width ?? {xs: '150px', sm: '226px'},
        height: props.height ?? {xs: '40px', sm: '60px'},
        textDecoration: 'none',
        // position: 'absolute',
        // left: {xs: '16px', sm: '36px'},
        // top: {xs: '11px', sm: '15px'},
        // bottom: {xs: '11px', sm: '15px'},
    }}>&nbsp;</Link>;
  }

export default MainLogo;