import { Box } from "@mui/material";
import React from "react";
import { NumberFormatter } from "../../shared/formatter";
import { OfferHelper } from "../../shared/offer-helper";
import { Offer } from "../../store/actions/offers";

class OfferAmountDto {
    offer!: Offer;
}

export default function OfferAmount(props: OfferAmountDto) {
    if (!props.offer) {
        return null;
    }
    let amount;
    const isOfferExpired  = OfferHelper.isOfferExpired(props.offer)
    if (OfferHelper.isOfferInProgress(props.offer)) {
        amount = (<Box sx={{
            fontSize:  '48px',
            fontWeight: '500',
            textTransform: 'uppercase',
            lineHeight: '48px',
            color: '#2A2F31'
        }}>Pending</Box>);
    } else {
        if(isOfferExpired) {
            amount = (
                <React.Fragment>
                    <Box sx={{fontSize: '36px', fontWeight: 500, color: '#999999'}}>$</Box>
                    <Box sx={{fontSize: '60px' ,  fontWeight: 500,  color: '#999999'}}>{NumberFormatter.format(String(props.offer.amount))}</Box>
                </React.Fragment>
            );
        } else {
            amount = (
                <React.Fragment>
                    <Box sx={{fontSize: '36px', color: '#2BACE2'}}>$</Box>
                    <Box sx={{fontSize: '60px', color: '#2BACE2'}}>{NumberFormatter.format(String(props.offer.amount))}</Box>
                </React.Fragment>
            );
        }
    }

    return (
        <Box sx={{
			display: 'flex',
			lineHeight: 1,
			fontWeight: 700,
            justifyContent: 'center'
		}}>
            {amount}
		</Box>
    );
}