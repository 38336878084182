import {VehicleHelper} from "../../shared/vehicle-helper";
import {Typography} from "@mui/material";
import React from "react";
import {Vehicle} from '../../store/actions/vehicles'

type VehicleNamePropsDto = {
	vehicle?: Vehicle
}

const VehicleName = (props: VehicleNamePropsDto) => {
	return (
		<Typography sx={{
			fontSize: {xs: '18px', sm: '24px'},
			lineHeight: {xs: '28px', sm: '32px'},
			fontWeight: {xs: 600, sm: 700},
			textTransform: 'uppercase',
			color: '#2A2F31',
		}}>
			{VehicleHelper.getTitle(props.vehicle)}
		</Typography>
	)
}

export default VehicleName