import { logoutSuccess } from "../store/actions/auth";

const regMap = [{
    reg: '(Network\\s+Error)|(timeout\\s+of\\s+\\d+ms\\s+exceeded)|(Request\\s+failed\\s+with\\s+status\\s+code\\s+401)',
    message: 'Service cannot be accessed at this time',
    flags: undefined,
}];

export const mapError = (error: any): string => {
    let message = '';
    
    if (error.response && error.response.data && error.response.data.error) {
        message = error.response.data.error;
    } else if (error instanceof Error) {
        message = error.message;
    } else if (typeof(error) === 'string') {
        message = error;
    }

    if (!message) {
        return message;
    }

    for (let idx in regMap) {
        const item = regMap[idx];
        const reg = new RegExp(item.reg, item.flags ?? undefined);
        if (reg.exec(message)) {
            message = item.message;
            break;
        }
    }

    return message;
}

export const dispatchError = (error: any) => {
    const isSessionExpired = !!(error && error.response && error.response.status === 401);
    const msg = mapError(error);
    
    return async (dispatch: any, getState: any) => {
        console.log('on handle error', error);
        if ((msg && msg.indexOf('Client is not found') > -1) || isSessionExpired) {
            dispatch(logoutSuccess());
        }
    }
}

export const errorInterceptor = (error: any) => {
    throw new Error(mapError(error));
}