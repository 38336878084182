import React, {useEffect, useState} from 'react';
import './RefreshOffers.css'
import {Box} from '@mui/material'
import {Button} from '../../../components/form/Button';
import {generateTag, getFormData, prepareFormData, validateForm} from "../../../shared/utility";
import {FormTextField} from "../../form/TextField";
import {FormData} from "../../form/TextField.dto";
import Question from "../../form/Question";
import {RootState} from "../../../app/store";
import {connect} from "react-redux";
import {NumberFormatter} from "../../../shared/formatter";
import {validationMileage} from "../../../shared/validationRules";
import {refreshOffers} from "../../../store/actions/refreshOffers";


let formData: FormData = {
    controls: {
        mileage: {
            label: 'CURRENT MILEAGE',
            value: '',
            validation: validationMileage,
            valid: false,
            touched: false,
            key: "",
            helperText: ""
        },
        damage: {
            label: 'Any new damage or mechanical issues?',
            value: null,
            values: [
                {key: "yes", value: "Yes"},
                {key: "no", value: "No"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

class RefreshOffersFormPropsDto {
    selectedVehicle: any;
    send: any;
    onClose: any;
}

const RefreshOffersForm = (props: RefreshOffersFormPropsDto) => {
    formData = prepareFormData(formData, {})

    const [formState, setFormState] = useState(formData)

    const mileageField = formState.controls.mileage
    const damageField = formState.controls.damage

    useEffect(() => {
        mileageField.validation.numericality.greaterThanOrEqualTo = props.selectedVehicle.mileage
    }, [props.selectedVehicle.mileage])

    useEffect(() => {
        document.getElementsByClassName("refresh-offers-container")[0]?.scrollIntoView();
    }, []);

    const handlerSubmit = (event: any) => {
        event.preventDefault()
        if (validateForm(formState, setFormState)) {
            const data = getFormData(formState)
            props.send(data, () => {
                    props.onClose()
                }
            )
        }
    }

    return (
        <div className='refresh-offers-container'>
            <div className='title'>Refresh Your Offers</div>
            <div className='content'>
                <div className='top-align'>
                    <div className='subheader'>To get you the most accurate offers, we need to know what has changed
                        with your vehicle.
                    </div>
                    <div className='divider'/>
                    <div className='question-block'>
                        <Box className='question-label'>What is your current mileage?</Box>
                        <FormTextField
                            field={mileageField}
                            formState={formState}
                            setFormState={setFormState}
                            type='input'
                        />
                        <Box className={'hint'}>Previous Mileage: {NumberFormatter.format(String(props.selectedVehicle.mileage))}</Box>
                    </div>
                    <Question
                        field={damageField}
                        formState={formState}
                        setFormState={setFormState}
                        isInOneLine
                        id={generateTag(damageField.key!, {snippet: '{%option%}', suffix: 'field'})}
                    />
                </div>
                <div className='button'>
                    <Button
                        onClick={handlerSubmit}
                        disabled={!formState.valid}
                        id={generateTag('continue', {prefix: 'modal-refresh-offers-form', suffix: 'btn'})}
                    >
                        Continue
                    </Button>
                    <div className="or-wrapper">– OR –</div>
                    <Button
                        variant="outlined"
                        paddingLeft="71px"
                        onClick={props.onClose}
                        id={generateTag('close', {prefix: 'modal-refresh-offers-form', suffix: 'btn'})}
                    >
                        CLOSE
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        selectedVehicle: state.vehicles.selected,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        send: (data: any, cb: any) => dispatch(refreshOffers(data, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshOffersForm)
