import React from "react";
import './index.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {inputChangedHandler, sanitizeString} from "../../../shared/utility";
import { Box } from '@mui/material'
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";

export class QuestionProps {
    id?: string | null;
    field: any;
    formState: any;
    setFormState: any;
    onChange?: any;
    dependencyControls?: string[];
    isUpperCase?: boolean;
    isInOneLine?: boolean;
    questionTitle?: string;
}

function Question(props: QuestionProps): JSX.Element {
    const {id = null, field, formState, setFormState, onChange, dependencyControls = [], isUpperCase = false, isInOneLine} = props

    let questionTitle = field ? field.label : props.questionTitle
    let answers = field && field.values ? field.values : [{key: field.value, value: field.value}]

    const handleChange = (event: React.MouseEvent<HTMLElement>, newTab: any) => {
        if (onChange) {
            onChange(newTab)
        } else {
            inputChangedHandler(formState, setFormState, newTab, field.key, true)
        }
    };

    const buttonItems = answers.map((item: any) =>
        <ToggleButton
            id={id ? id.replaceAll('{%option%}', sanitizeString(item.value)) : field.key}
            key={item.key}
            value={item.key}
            disabled={answers.length === 1}
            disableRipple={true}
            sx={{
                borderRadius: "24px",
                fontSize: '18px',
                lineHeight: '28px',
                fontWeight: 400,
                letterSpacing: '0.01em',

                border: "2px solid #B3B3B3",
                padding: '11px',
                color: '#2A2F31',

                '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                    margin: 0,
                    border: "2px solid #B3B3B3",
                    borderRadius: '24px',
                    '&.Mui-selected': {
                        border: 'none',
                    }
                },
                '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                    margin: 0,
                    border: "2px solid #B3B3B3",
                    borderRadius: '24px',
                    '&.Mui-selected': {
                        border: 'none',
                    }
                },
                ':hover': {
                    backgroundColor: 'inherit',
                    padding: '11px',
                },
                '&.Mui-selected': {
                    border: 'none',
                    padding: '13px',
                    backgroundColor: '#2BACE2',
                    color: '#FFFFFF',
                    fontWeight: 600,
                    '&:hover': {
                        backgroundColor: '#2BACE2',
                    }
                },
            }}
        >
            <Box className={'toogle-button-content'} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}>
                {field.value === item.key && answers.length === 1 && <Box sx={{ml: '7px', visibility: 'hidden'}}><CheckImg className="checkImg"/></Box>}
                <Box sx={{
                    flexGrow: 1,
                }}>
                    {item.value}
                </Box>
                {field.value === item.key && answers.length === 1 && <Box sx={{mr: '7px'}}><CheckImg className="checkImg"/></Box>}
            </Box>
        </ToggleButton>
    );

    const inOneLineStyles: any = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "10px",
        marginTop: "10px",
    }

    return (
        <div className='question-block'>
            <Box component="div" className='question-title' sx={{textTransform: isUpperCase ? 'uppercase' : 'inherit'}}>{questionTitle}</Box>
            <ToggleButtonGroup
                orientation={isInOneLine ? "horizontal" : "vertical"}
                color="primary"
                value={field.value}
                exclusive
                onChange={handleChange}
                style={isInOneLine && inOneLineStyles}
            >
                {buttonItems}
            </ToggleButtonGroup>
        </div>
    )
}

export default Question;